.c-content-wrapper {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 72%;
  padding: 0 $space-md $space-lg;
  background-color: $color-white;

  @include mq(678px) {
    padding: 0 $space-xl $space-xl;
  }
}

  .c-sidebar--horizontal.is-open ~ .c-content-wrapper {
    padding-top: $space-xl;
  }

  .c-sidebar--horizontal ~ .c-content-wrapper {
    min-height: 88vh;
  }
