.c-table__wrapper {
  overflow: auto;
	background-attachment: local, local, scroll, scroll; // This makes the trick
	background-color: $color-white;

  background-image:
    // Shadows
    linear-gradient(to right, $color-white, $color-white),
    linear-gradient(to right, $color-white, $color-white),

    // Shadow covers
    linear-gradient(to right, rgba($color-grey-900, .15), rgba($color-grey-900, 0)),
    linear-gradient(to left, rgba($color-grey-900, .15), rgba($color-grey-900, 0));

  background-position: left center, right center, left center, right center;
	background-repeat: no-repeat;
	background-size: rem(20) 100%, rem(20) 100%, rem(10) 100%, rem(10) 100%;
}

  .c-table {
    margin: 0;
    border-radius: $global-radius;
    color: $color-grey-600;
    text-align: left;
  }
    .c-table--listing {
      width: 100%;
    }

    .c-table__header__row--title {
      position: relative;

      + .c-table__header__row {

        .c-table__header__item:first-child,
        .c-table__header__item:last-child {
          border-radius: 0;
        }
      }
    }

    .c-table__header__title {
      padding: $space-xs $space-lg;
      border-radius: $global-radius $global-radius 0 0;
      background-color: $color-brand-secondary-1000;
      color: $color-brand-primary-100;
      font-weight: 800;
    }

      .c-table__header__title-text {
        position: sticky;
        right: 43%;
        left: 43%;
      }

    .c-table__header__item {
      padding: $space-xs $space-unit;
      background-color: $color-brand-secondary-900;
      color: $color-brand-primary-100;
      font-size: $text-size-8;
      font-weight: 600;
      letter-spacing: rem(1);
      text-transform: uppercase;
      vertical-align: middle;
      white-space: nowrap;

      &:first-child {
        border-top-left-radius: $global-radius;
      }

      &:last-child {
        border-top-right-radius: $global-radius;
      }
    }

    .c-table__body {
      pointer-events: none;

      & .c-table__body__item {
        pointer-events: auto;
        transition: 0.3s all;
      }

      &:hover > *:nth-child(even) .c-table__body__item {
        background-color: transparent;
      }

      &:hover > *:hover .c-table__body__item {
        background-color: rgba($color-brand-primary-100, 0.3);
        box-shadow: inset 0 rem(1) 0 $color-brand-secondary-100,
                    inset 0 rem(-1) 0 $color-brand-secondary-100;

        &:first-child {
          box-shadow: inset rem(1) rem(1) 0 $color-brand-secondary-100,
                      inset 0 rem(-1) 0 $color-brand-secondary-100;
        }

        &:last-child {
          box-shadow: inset rem(-1) rem(1) 0 $color-brand-secondary-100,
                      inset 0 rem(-1) 0 $color-brand-secondary-100;
        }
      }
    }

    .c-table__body__row {
      background: transparent;

      &:nth-child(even) {

        .c-table__body__item {
          background-color: rgba($color-brand-primary-100, 0.2);
        }
      }

      &:last-child {

        .c-table__body__item {

          &:first-child {
            border-bottom-left-radius: $global-radius;
          }

          &:last-child {
            border-bottom-right-radius: $global-radius;
          }
        }
      }
    }

      .c-table__body__item {
        min-width: rem(160);
        height: rem(56);
        padding: $space-md $space-unit;
        box-shadow: inset 0 rem(-1) 0 $color-grey-300;
        font-size: rem(14);
        vertical-align: middle;
        white-space: nowrap;
        transition: $global-transition;

        &:first-child {
          box-shadow: inset rem(1) rem(-1) 0 $color-grey-300;
        }

        &:last-child {
          box-shadow: inset rem(-1) rem(-1) 0 $color-grey-300;
        }

        > .c-btn--icon + .c-btn--icon {
          margin-top: $space-xxxs;
        }
      }

      .c-table__body__item--action {
        min-width: auto;
        text-align: right;
      }

        .o-icon--edit {
          fill: $color-brand-secondary-900;
        }

        .c-table__role,
        .c-table__login,
        .c-table__body__item--action {
          font-size: em(13);
        }

        .c-table__role {
          display: inline-block;
          margin: $space-xxxs $space-xs 0 0;
          color: $color-grey-500;
        }

        .c-table__login {
          color: $color-success-600;

          .o-icon--approval {
            width: rem(12);
            vertical-align: middle;
          }
        }

  .c-table--reporting__actions {
    z-index: 10;
    position: relative;
    margin-top: rem(-120);
    padding: rem(80) 0 rem(24);
    border: rem(1) solid $color-grey-300;
    border-top: 0;
    border-radius: 0 0 $global-radius $global-radius;
    text-align: center;
  }

  .c-table--reporting {

    .c-table__body__item {
      min-width: auto;
    }
  }

  .c-table--compact {
    width: auto;

    .c-table__header__title {
      text-align: center;
    }

    .c-table__header__title-text {
      position: static;
    }

    .c-table__header__item {
      padding: $space-xxs;
      white-space: normal;
    }

    .c-table__body__item {
      min-width: min-content;
      width: auto;
      height: auto;
      padding: $space-xxs;
      white-space: normal;
    }
  }


  .c-table--summary {

    .c-table__header__item {
      padding: $space-xs;
      background-color: $color-brand-primary-200;
      color: $color-brand-secondary-900;
      white-space: normal;
    }

    .c-table__body__item {
      min-width: auto;
      height: auto;
      padding: $space-xs;
      white-space: normal;
    }

    .c-table__header__item:last-child,
    .c-table__body__item:last-child {
      text-align: right;
    }
  }


/////////////////////////////
// Fix table column classes
////////////////////////////
$table-height: rem(56);
$table-width: rem(180);
$table-even-fix-color: #fafbfd;
$table-fix-shadow: rem(2) 0 rem(1) 0 rgba($color-grey-900, 0.2);
$table-fix-shadow--right: rem(-2) 0 rem(1) 0 rgba($color-grey-900, 0.2);


@mixin fix-column() {
  position: sticky;
  left: 0;
  min-width: $table-width;
  min-height: $table-height;
  text-align: center;
}


.c-table--fix-col-1 {

  .c-table__body__row {

    .c-table__body__item:nth-child(1)  {
      background-color: $color-white;
    }

    &:nth-child(even) {

      .c-table__body__item:nth-child(1) {
        background-color: $table-even-fix-color;
      }
    }
  }

  .c-table__body:hover {

    > *:nth-child(even) .c-table__body__item:nth-child(1) {
      background-color: $color-white;
    }

    > *:hover .c-table__body__item:nth-child(1) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow,
                  inset rem(1) rem(-1) 0 $color-grey-300;
    }

    > *:hover:last-child .c-table__body__item:nth-child(1) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow,
                  inset rem(1) rem(-1) 0 $color-grey-300;
    }
  }

  .c-table__header__item:nth-child(1),
  .c-table__body__item:nth-child(1) {
    @include fix-column;
    min-width: max-content;
    box-shadow: $table-fix-shadow;
  }

  .c-table__body__item:nth-child(1):first-child {
    box-shadow: $table-fix-shadow,
                inset rem(1) rem(-1) 0 $color-grey-300;
  }
}

.c-table--fix-col-2 {

  .c-table__body__row {

    .c-table__body__item:nth-child(-n+2)  {
      background-color: $color-white;
    }

    &:nth-child(even) {

      .c-table__body__item:nth-child(-n+2) {
        background-color: $table-even-fix-color;
      }
    }
  }

  .c-table__body:hover {

    > *:nth-child(even) .c-table__body__item:nth-child(-n+2) {
      background-color: $color-white;
    }

    > *:hover .c-table__body__item:nth-child(-n+2) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow,
                  inset 0 rem(-1) 0 $color-grey-300;
    }

    > *:hover:last-child .c-table__body__item:nth-child(-n+2) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow,
                  inset 0 rem(-1) 0 $color-grey-300;
    }

    > *:hover .c-table__body__item:first-child,
    > *:hover:last-child .c-table__body__item:first-child {
      box-shadow: inset rem(1) rem(-1) 0 $color-grey-300;
    }
  }


  .c-table__header__item:nth-child(-n+2),
  .c-table__body__item:nth-child(-n+2) {
    @include fix-column;
  }

  .c-table__header__item:nth-child(2),
  .c-table__body__item:nth-child(2) {
    left: $table-width;
    box-shadow: $table-fix-shadow;
  }

  .c-table__body__item:nth-child(2) {
    box-shadow: $table-fix-shadow,
                inset 0 rem(-1) 0 $color-grey-300;
  }

  .c-table__body__item:nth-child(2):first-child {
    box-shadow: $table-fix-shadow,
                inset rem(1) rem(-1) 0 $color-grey-300;
  }
}

.c-table--fix-col-3 {

  .c-table__body__row {

    .c-table__body__item:nth-child(-n+3)  {
      background-color: $color-white;
    }

    &:nth-child(even) {

      .c-table__body__item:nth-child(-n+3) {
        background-color: $table-even-fix-color;
      }
    }
  }

  .c-table__body:hover {

    > *:nth-child(even) .c-table__body__item:nth-child(-n+3) {
      background-color: $color-white;
    }

    > *:hover .c-table__body__item:nth-child(-n+3) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow,
                  inset 0 rem(-1) 0 $color-grey-300;
    }

    > *:hover:last-child .c-table__body__item:nth-child(3) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow,
                  inset 0 rem(-1) 0 $color-grey-300;
    }

    > *:hover .c-table__body__item:nth-child(1) {
      box-shadow: inset rem(1) rem(-1) 0 $color-grey-300;
    }

  }

  .c-table__header__item:nth-child(-n+3),
  .c-table__body__item:nth-child(-n+3) {
    @include fix-column;
  }

  .c-table__header__item:nth-child(2),
  .c-table__body__item:nth-child(2) {
    left: $table-width;
  }

  .c-table__header__item:nth-child(3),
  .c-table__body__item:nth-child(3) {
    left: $table-width * 2;
    box-shadow: $table-fix-shadow;
  }

  .c-table__body__item:nth-child(3) {
    box-shadow: $table-fix-shadow,
                inset 0 rem(-1) 0 $color-grey-300;
  }

  .c-table__body__item:nth-child(3):first-child {
    box-shadow: $table-fix-shadow,
                inset 0 rem(-1) 0 $color-grey-300;
  }
}


///////////////
// Right fixed
///////////////

.c-table--fix-col-1--right {

  .c-table__body__row {

    .c-table__body__item:nth-last-child(1)  {
      background-color: $color-white;
    }

    &:nth-last-child(even) {

      .c-table__body__item:nth-last-child(1) {
        background-color: $table-even-fix-color;
      }
    }
  }

  .c-table__body:hover {

    > *:nth-last-child(even) .c-table__body__item:nth-last-child(1) {
      background-color: $color-white;
    }

    > *:hover .c-table__body__item:nth-last-child(1) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow--right,
                  inset rem(-1) rem(-1) 0 $color-grey-300;;
    }

    > *:hover:last-child .c-table__body__item:nth-last-child(1) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow--right,
                  inset rem(-1) rem(-1) 0 $color-grey-300;
    }
  }

  .c-table__header__item:nth-last-child(1),
  .c-table__body__item:nth-last-child(1) {
    @include fix-column;
    right: 0;
    left: auto;
    min-width: max-content;
    box-shadow: $table-fix-shadow--right;
  }

  .c-table__body__item:nth-last-child(1):first-child,
  .c-table__body__item:nth-last-child(1):last-child {
    box-shadow: $table-fix-shadow--right,
                inset rem(-1) rem(-1) 0 $color-grey-300;
  }
}

.c-table--fix-col-2--right {

  .c-table__body__row {

    .c-table__body__item:nth-last-child(-n+2)  {
      background-color: $color-white;
    }

    &:nth-last-child(even) {

      .c-table__body__item:nth-last-child(-n+2) {
        background-color: $table-even-fix-color;
      }
    }
  }

  .c-table__body:hover {

    > *:nth-last-child(even) .c-table__body__item:nth-last-child(-n+2) {
      background-color: $color-white;
    }

    > *:hover .c-table__body__item:nth-last-child(-n+2) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow--right,
                  inset 0 rem(-1) 0 $color-grey-300;
    }

    > *:hover:last-child .c-table__body__item:nth-last-child(-n+2) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow--right,
                  inset 0 rem(-1) 0 $color-grey-300;
    }

    > *:hover .c-table__body__item:last-child,
    > *:hover:last-child .c-table__body__item:last-child {
      box-shadow: inset rem(-1) rem(-1) 0 $color-grey-300;
    }
  }


  .c-table__header__item:nth-last-child(-n+2),
  .c-table__body__item:nth-last-child(-n+2) {
    @include fix-column;
    right: 0;
    left: auto;
  }

  .c-table__header__item:nth-last-child(2),
  .c-table__body__item:nth-last-child(2) {
    right: $table-width;
    box-shadow: $table-fix-shadow--right;
  }

  .c-table__body__item:nth-last-child(2) {
    box-shadow: $table-fix-shadow--right,
                inset 0 rem(-1) 0 $color-grey-300;
  }

  .c-table__body__item:nth-last-child(2):first-child {
    box-shadow: $table-fix-shadow--right,
                inset rem(-1) rem(-1) 0 $color-grey-300;
  }
}

.c-table--fix-col-3--right {

  .c-table__body__row {

    .c-table__body__item:nth-last-child(-n+3)  {
      background-color: $color-white;
    }

    &:nth-last-child(even) {

      .c-table__body__item:nth-last-child(-n+3) {
        background-color: $table-even-fix-color;
      }
    }
  }

  .c-table__body:hover {

    > *:nth-last-child(even) .c-table__body__item:nth-last-child(-n+3) {
      background-color: $color-white;
    }

    > *:hover .c-table__body__item:nth-last-child(-n+3) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow--right,
                  inset 0 rem(-1) 0 $color-grey-300;
    }

    > *:hover:last-child .c-table__body__item:nth-last-child(3) {
      background-color: $table-even-fix-color;
      box-shadow: $table-fix-shadow--right,
                  inset 0 rem(-1) 0 $color-grey-300;
    }

    > *:hover .c-table__body__item:nth-last-child(2) {
      box-shadow: inset 0 rem(-1) 0 $color-grey-300;
    }

    > *:hover .c-table__body__item:nth-last-child(1) {
      box-shadow: inset rem(-1) rem(-1) 0 $color-grey-300;
    }

  }

  .c-table__header__item:nth-last-child(-n+3),
  .c-table__body__item:nth-last-child(-n+3) {
    @include fix-column;
    right: 0;
    left: auto;
  }

  .c-table__header__item:nth-last-child(2),
  .c-table__body__item:nth-last-child(2) {
    right: $table-width;
  }

  .c-table__header__item:nth-last-child(3),
  .c-table__body__item:nth-last-child(3) {
    right: $table-width * 2;
    box-shadow: $table-fix-shadow--right;
  }

  .c-table__body__item:nth-last-child(3) {
    box-shadow: $table-fix-shadow--right,
                inset 0 rem(-1) 0 $color-grey-300;
  }

  .c-table__body__item:nth-last-child(3):first-child {
    box-shadow: $table-fix-shadow--right,
                inset 0 rem(-1) 0 $color-grey-300;
  }
}

.c-table--qty-fix {

  .c-table__header__item:nth-last-child(1),
  .c-table__body__item:nth-last-child(1) {
    min-width: rem(124);
    padding: 0 $space-unit;
    text-align: center;
  }

  .c-table__body__item:nth-last-child(1) {
    height: rem(74);
  }

  .c-table__header__item > .c-btn--primary {
    min-height: auto;
    border-radius: $global-radius;
    font-size: $text-size-8;
    letter-spacing: normal;

    &:first-letter {
      text-transform: uppercase;
    }

    @include hocus() {
      text-decoration: none;
    }
  }
}

  .c-table--qty-fix__actions {
    display: flex;
    position: absolute;
    right: 0;
    bottom: rem(5);
    left: 0;
    justify-content: space-evenly;
    width: 100%;
    font-size: rem(11);
    text-align: center;
    text-transform: uppercase;
  }

  ///////////////////
  // Inventory table
  ///////////////////

.c-table--inventory {

  .c-table__body__item {
    min-width: auto;
  }
}


// New product table
.c-table__body__item--new-product {
  font-weight: 600;
}

/////////////////////////
// Select material table
////////////////////////

.c-list--inventory__item__header--transfer + .c-table--qty-fix {

  .c-btn--plus-minus {

    &:first-child {
      left: 1rem;
    }

    & + * + .c-btn--plus-minus {
      left: 5.3rem;
    }
  }
}

///////////////////////////////
// Assigning attributes table
//////////////////////////////

.c-table--assigning {

  .c-table__body__row--parent {

    .c-table__body__item,
    &:hover .c-table__body__item,
    .c-table__body__item:nth-last-child(-n+3),
    .c-table__body:hover &:hover .c-table__body__item:nth-last-child(-n+3),
    .c-table__body:hover &:hover .c-table__body__item,
    &.c-table__body__row:nth-last-child(2n) .c-table__body__item:nth-last-child(-n+3) {
      background-color: $color-grey-200;
      color: $color-grey-500;
    }

    ~ * {

      .c-table__body__item {
        transform: translateX($space-unit);

        &:first-child ~ *:not(:nth-last-child(-n+3)) {
          padding-left: 0;
        }

        &:last-child {

          .ember-power-select-trigger {
            min-width: rem(70);
  
            @include hocus() {
              position: absolute;
              right: $space-unit;
        
              .ember-power-select-selected-item {
                max-width: max-content;
                min-width: rem(50);
              }
            }
  
            .ember-power-select-selected-item {
              max-width: rem(70);
            }
          }
        }
      }

      .c-table__body__item:nth-last-child(-n+3) {
        transform: translateX(0);
      }
    }
  }

  .c-table__body__row--parent + .c-table__body__row::after {
     display: block;
     position: absolute;
     right: 0;
     width: 22.25rem;
     height: 4rem;
     transform: translateY(rem(-64));
     background: $color-white;
     box-shadow: $table-fix-shadow--right,
                 inset rem(-1) 0 0 $color-grey-300,
                 inset 0 rem(-1) 0 $color-grey-300;
     content: '';
   }

  .c-table__body:hover .c-table__body__row--parent:hover .c-table__body__item:nth-last-child(-n+3),
  .c-table__body__row--parent .c-table__body__item:nth-last-child(-n+3) {
    position: static;
    box-shadow: inset 0 rem(-1) 0 $color-grey-300;
    text-align: left;
  }


  .c-table__header__item:nth-last-child(-n+3),
  .c-table__body__item:nth-last-child(-n+3) {
    min-width: auto;
  }

  .c-table__body__item:last-child {
    min-width: 8rem;
  }

  .c-table__body__item:nth-last-child(2),
  .c-table__header__item:nth-last-child(2) {
    right: 8rem;
    min-width: 6rem;
  }

  .c-table__body__item:nth-last-child(3),
  .c-table__header__item:nth-last-child(3) {
    right: 14rem;
  }
}

  .c-table__body__remaining-items {
    position: absolute;
    z-index: 10;
    right: 7.6rem;
    top: 2.8rem;
    font-size: $text-size-7;
    background-color: lighten($color-danger-100, 3%);
    border: rem(1) solid $color-danger-100;
    color: $color-danger-900;
    padding: $space-xxxs $space-xs;
    border-radius: $global-radius;
  }
