.c-tab-nav__list {
  display: flex;
  align-items: center;
  overflow: auto;
}
  .c-tab-nav__item {
    margin-right: $space-md
  }
    .c-tab-nav__link {
      position: relative;
      padding: $space-xs $space-sm;
      border-radius: 0;
      color: rgba($color-brand-secondary-800, 0.5);
      font-size: $text-size-6;
      text-decoration: none;
      text-transform: uppercase;

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        margin: 0 auto;
        background-color: $color-brand-secondary-800;
        content: '';
        transition: $global-transition--slow;
      }

      @include acthocus {
        color: $color-brand-secondary-800;
        text-decoration: none;
      };

      &:focus {
        outline: rem(1) dotted $color-grey-200;
        box-shadow: none;
      }

      &.is-active {

        &::after {
          width: 100%;
          height: rem(2.6);
        }
      }

      &:active {
        padding: $space-sm $space-sm $space-xxs;
      }
    }
