.c-filter-box {
  padding: $space-lg $space-md $space-unit;
  border: rem(1.5) solid $color-grey-400;
  border-radius: $global-radius;
  //background: $color-gradient--md;
}

  .c-filter-box__description {
    margin-top: $space-sm;
    color: $color-grey-800;
    line-height: 1.5;
  }

  .c-filter-box__extra-filters {
    display: none;
    width: calc(#{$space-md} * 2 + 100%);
    max-width: calc(#{$space-md} * 2 + 100%);
    margin: 0 (-$space-md) (-$space-unit);
    padding: $space-md $space-md $space-lg;
    background-color: $color-grey-100;

    .is-open + &,
    &.is-open {
      display: grid;
    }
  }
