table {
  border-collapse: collapse;
  border-spacing: 0;
}

p {
  @include text-preset-7;
}

dd {
  color: $color-grey-600;
}

input {
  color: $color-grey-800;
}
