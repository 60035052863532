
.c-summary__wrapper {
  grid-gap: $space-unit;
  display: grid;
  grid-template-columns: $global-grid-columns;
}

  .c-summary__content {
    grid-column: span 9;
  }

  .c-summary__sidebar {
    display: flex;
    grid-column: span 3;
    flex-direction: column;
  }

    .c-summary__sidebar__header {
      display: flex;
      z-index: 20;
      position: sticky;
      top: 0;
      justify-content: space-between;
      padding-bottom: $space-unit;
      overflow: hidden;
      background-color: $color-white;

      > .c-btn {
        top: rem(2);
        margin: 0;
      }
    }

      .c-summary__sidebar__title {
        margin: 0;
        @include text-preset-4--sm;
      }

    .c-summary__sidebar__content {
      flex: 1;
      padding: 0 $space-xs;
      background-color: $color-grey-200;

      & > * {
        z-index: 10;
        position: sticky;
        top: $space-xl;
        margin-top: $space-unit;
      }
    }

      .c-summary__sidebar__content-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

//
// .sidebar-summary__sum-header {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//
//   .button,
//   .edit {
//     margin-top: px-to-rems(16);
//     margin-left: auto;
//   }
// }
//
// .sidebar-summary__sum-title {
//   z-index: 10;
//   position: -webkit-sticky;
//   position: sticky;
//   top: px-to-rems(8);
//   font-size: px-to-rems(16);
//   font-weight: 600;
// }
//
// .sidebar-summary__sum-driver {
//   padding: px-to-rems(2) 0 px-to-rems(8);
//   opacity: 0.7;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   width: 100%;
//   border-bottom: px-to-rems(1) solid $mid-gray;
// }
//
// .sidebar-summary__sum-driver-label {
//   text-transform: uppercase;
//   flex-basis: 100%;
// }
//
// .sidebar-summary__sum-driver__name,
// .sidebar-summary__sum-driver__company,
// .sidebar-summary__sum-driver-label  {
//   font-size: px-to-rems(13);
// }
//
// .sidebar-summary__sum-driver__company,
// .sidebar-summary__sum-driver-label  {
//   font-weight: 600;
// }
//
// .sidebar-summary__sum-content {
//   height: 100%;
//   padding: 0 px-to-rems(8);
//   clear: both;
//   background-color: $summary-bg;
//
//   > div {
//     z-index: 10;
//     position: -webkit-sticky;
//     position: sticky;
//     top: px-to-rems(48);
//   }
//
//   &.sum__content-height {
//     height: auto;
//     padding-bottom: px-to-rems(16);
//   }
// }
//
// .sidebar-summary__sum-company {
//   margin-top: px-to-rems(16);
//   letter-spacing: px-to-rems(1);
//   text-transform: uppercase;
//
//   &:only-child {
//     margin-top: px-to-rems(4);
//     font-size: px-to-rems(10);
//     opacity: 0.4;
//   }
// }
//
//
// .sidebar-summary__sum-content .summary__table {
//   margin-top: px-to-rems(8);
//   border-radius: px-to-rems(2);
//   border-collapse: separate;
//
//   tr:first-child td:first-child { border-top-left-radius: px-to-rems(2); }
//   tr:first-child td:last-child { border-top-right-radius: px-to-rems(2); }
//   tr:last-child td:first-child { border-bottom-left-radius: px-to-rems(2); }
//   tr:last-child td:last-child { border-bottom-right-radius: px-to-rems(2); }
// }
//
// .summary__th {
//   font-weight: 600;
//
//   &:last-child {
//     text-align: center;
//   }
//
//   &:first-child,
//   &:last-child {
//     padding: 0 0 px-to-rems(4);
//   }
// }
//
// .summary-row {
//   background-color: transparent;
//
//   td {
//     padding: px-to-rems(8);
//     background-color: $white;
//     box-shadow: 0 px-to-rems(1) px-to-rems(4) rgba($black, 0.1);
//     vertical-align: middle;
//   }
// }
