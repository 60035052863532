.is-editable,
.o-grid.is-editable,
.selected-section {
  background-color: $color-brand-secondary-0;
  padding: $space-unit;
  margin: $space-xs -#{$space-unit} -#{$space-unit};
  border: rem(1) dashed $color-warning-300;
  background-color: rgba($color-warning-100, 0.3);
}

.selected-section {
  background-color: rgba($color-brand-primary-100, 0.3);
  border-color: $color-brand-secondary-300;
}
