.c-order-logs {
  background-color: $color-white;
  box-shadow: $global-box-shadow--md;
  border-radius: $global-radius;
  border: rem(1) solid $color-grey-100;
  margin: 0 $space-unit;
  padding: $space-unit;

  > * + * {
    margin-top: $space-unit;
  }
}

  .c-order-logs__item {
    border: rem(1) solid $color-grey-300;
    border-radius: $global-radius;
    padding: $space-xs;
    display: flex;
    align-items: center;

    > * + * {
      margin-left: $space-unit;
    }
  }

    .c-order-logs__item-date {
      text-align: center;
      line-height: 0.9;

      > * {
        font-weight: 600;
        opacity: 0.8;
      }
    }

      .c-order-logs__item-date__day {
        display: block;
        font-size: rem(26);
      }

      .c-order-logs__item-date__month {
        font-size: rem(14);
      }

  .c-order-logs__item-content {
    margin-top: rem(-1);
    font-size: $text-size-7;
    flex-basis: 100%;

    > * + * {
      margin-top: $space-xxxxs;
      max-width: 100%;
    }

    & + & {
      flex-basis: min-content;
    }
  }

    .c-order-logs__item-heading {
      font-size: $text-size-6;
    }
