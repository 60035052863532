.u-ember-input {

  input {
    width: 100%;
    height: $input-height;
    padding: $space-xs;
    border: $input-borders;
    border-radius: $global-radius;
  }
}
