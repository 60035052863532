.c-notification {
  position: sticky;
  top: 0;
  margin: 0 -#{$space-xl};
  padding: $space-xxs $space-xl;
  background-color: $color-brand-primary-100;
  color: $color-brand-primary-700;
  z-index: 100;
  animation: 0.8s slidein--top;
}

  .c-notification--deploy-staging {
    background-color: lighten($color-order-history, 10%);
    color: darken($color-order-history, 30%);
  }

  .c-notification--deploy-development {
    background-color: $color-reporting;
    color: $color-white;
  }
