/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

/**
 * 1. Allow us to style box model properties.
 * 2. Reset/normalize some styles.
 * 3. Line different sized buttons up a little nicer.
 * 4. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  min-width: rem(36);
  min-height: rem(36);
  padding: $space-sm $space-unit;
  border: rem(1) solid $color-btn-border;
  border-radius: $global-radius;
  background: $color-btn-background;
  box-shadow: $global-box-shadow--light;
  color: $color-btn;
  font: inherit; /* [3] */
  font-size: rem(14);
  font-weight: 600;
  text-align: center; /* [2] */
  text-decoration: none; /* [2] */
  vertical-align: middle; /* [4] */
  cursor: pointer; /* [5] */
  transition: $global-transition--fast;

  @include hocus() {
    background: $color-btn-background-hover;
    color: darken($color-btn, 10%);
    text-decoration: none;
  }

  &:focus {
  // box-shadow is invisible in Windows high-contrast mode, so we need to add a transparent outline, which actually does show up in high-contrast mode.
  outline: rem(2) dotted transparent;
  outline-offset: rem(2);
  // Adds a white border around the button, along with a blue glow. The white and blue have a color contrast ratio of at least 3:1, which means this will work against any background color.
  box-shadow: 0 0 0 rem(2) $color-white, 0 0 rem(3) rem(5) $color-brand-secondary-highlight;
  }

  &:active {
    padding-top: 1.08 * $space-sm;
    padding-bottom: 0.92 * $space-sm;
    box-shadow: $global-box-shadow__inset;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

// Size variations

.c-btn--xs {
  min-width: rem(16);
  min-height: rem(24);
  padding: $space-xxxs $space-xs;
  font-size: rem(12);
  font-weight: 500;

  &:active {
    padding-top: 1.08 * $space-xxxs;
    padding-bottom: 0.92 * $space-xxxs;
  }
}

.c-btn--sm {
  min-width: rem(16);
  min-height: rem(24);
  padding: $space-xs $space-sm;
  font-size: rem(13);
  font-weight: 500;

  &:active {
    padding-top: 1.08 * $space-xs;
    padding-bottom: 0.92 * $space-xs;
  }
}

.c-btn--lg {
  padding: $space-unit $space-md;
  font-size: rem(16);

  &:active {
    padding-top: 1.08 * $space-unit;
    padding-bottom: 0.92 * $space-unit;
  }
}


.c-btn--primary {
  border-color: $color-btn-primary-border;
  background: $color-btn-primary-background;
       color: $color-btn-primary;

  @include hocus() {
    background: $color-btn-primary-background-hover;
    color: $color-btn-primary;
  }

  &:active {
    border-color: $color-brand-secondary-700;
    background: $color-btn-primary-background-active;
    box-shadow: $global-box-shadow__inset--md;
    color: $color-btn-primary;
  }
}

.c-btn--outline {
  border: $space-unit * 0.08 solid currentColor;
  background: $color-btn-outline-background;
  box-shadow: none;
  color: $color-btn-outline;

  @include hocus() {
    background: $color-btn-outline-background-hover;
    color: darken($color-brand-secondary, 2%);
  }

  &.is-active,
  &:active,
  input:checked + & {
    border: rem(1.5) solid lighten($color-brand-secondary-700, 2%);
    background-color: lighten($color-brand-primary-100, 2%);
    color: $color-brand-secondary-700;
  }
}
  //////////////////////////////////////////////////////////
  // Radio or checkbox input with outline button style
  //////////////////////////////////////////////////////////
  .c-input--btn-outline {
    display: inline-block;
    align-self: center;

    input {
      display: none;

      &:checked + .c-btn--outline--fail {
        border-color: $color-danger-600;
        background-color: $color-danger-100;
        color: $color-danger-600;
      }

      &:checked + .c-btn--outline--success {
        border-color: $color-success-600;
        background-color: rgba($color-success-100, 0.8);
        color: $color-success-600;
      }
    }

    .c-btn {
      width: 100%;
    }

    > .c-btn--outline.c-btn--outline--fail {
      color: $color-danger-500;

      @include hocus() {
        background: rgba($color-danger-100, 0.4);
      }

      &.is-active,
      &:active,
      input:checked + & {
        border-color: $color-danger-600;
        background-color: rgba($color-danger-100, 0.8);
        color: $color-danger-600;
      }
    }

    > .c-btn--outline.c-btn--outline--success {
      color: $color-success-500;

      @include hocus() {
        background: rgba($color-success-100, 0.4);
      }

      &.is-active,
      &:active,
      input:checked + & {
        border-color: $color-success-600;
        background-color: rgba($color-success-100, 0.8);
        color: $color-success-600;
      }
    }
  }


.c-btn--link {
  position: relative;
  width: max-content;
  min-width: min-content;
  max-width: max-content;
  border: 0;
  background: transparent;
  box-shadow: none;
  color: $color-link;

  @include acthocus() {
    text-underline-offset: $space-xxxs;
    border: 0;
    background: transparent;
    background: transparent;
    box-shadow: none;
    color: $color-link-hover;
    text-decoration: underline solid rem(1.5);
  }


  &.c-btn--link--no-underline {

    @include acthocus() {
      text-decoration: none;
    }
  }
}

.c-btn--block {
  display: block;
  width: 100%;
}

.c-btn--icon {
  display: inline-flex;
  align-items: center;
}

.c-btn--icon--right {

  .o-icon {
    margin: 0 0 0 $space-xxxs;
  }
}

.c-btn--destructive {
  border: $space-unit * 0.08 solid $color-btn-danger-border;
  background: $color-btn-danger-background;
       color: $color-btn-danger;

  @include hocus() {
    background: $color-btn-danger-background-hover;
    color: $color-btn-danger;
  }

  &:active {
    border-color: $color-danger-700;
    background: $color-btn-danger-background-active;
    box-shadow: $global-box-shadow__inset--md;
    color: $color-white;
  }
}

.c-btn--disabled,
button:disabled,
input:disabled {
  background-color: $color-grey-200;
             color: $color-grey-400;
  cursor: not-allowed;

  @include hocus() {
   background-color: $color-grey-200;
    color: $color-grey-400;
  }
}

.c-btn--sticky {
  position: sticky;
  top: 0;
  margin: -1.5rem 0 0 auto;
  z-index: 20;
  background: $color-danger-100;
  color: $color-danger-800;
  border: 0;
  align-self: center;

  @include hocus() {
    background: lighten($color-danger-100, 2%);
  }
}

.c-btn--plus-minus {
  position: absolute;
  width: $space-unit;
  height: $space-unit;
  fill: currentColor;
  margin: $space-xs;

  &:first-child {
  //  left: 0.75rem;
  }

  & + * + .c-btn--plus-minus {
    left: 5.5rem;
  }
}

.c-btn-pink {
  background-color: pink !important;
}