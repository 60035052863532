@keyframes slidein--top {
  0% {
    transform: translateY(rem(-50));
  }

  70% {
    transform: translateY(rem(2));
  }

  75% {
    transform: translateY(rem(-1));
  }

  100% {
    transform: translateY(0);
  }
}
