body {
  background-color: $color-white;
}

////////////
// #TABLES
///////////

th:first-child,
th:last-child,
table td:first-child,
table td:last-child,
.summary th:first-child {
  padding: $space-xs $space-unit;
}

//.button-scroll,
//.sidebar__header__username,
th {
  text-transform: none;
}

.admin-collection table {
  border-collapse: collapse;
}

////////////
// #GLOBAL
///////////

* {
  font-size: inherit;
}

//////////////////////////
// #POWER SELECT PLUGIN
//////////////////////////

.ember-power-select-multiple-option {
  border: inherit;
  border-radius: $global-radius;
  color: $color-white;
  float: none;
  margin: 0;
}

.ember-power-select-trigger-multiple-input {
  outline: 0;
  padding: 0;
  float: none;
  text-indent: 2px;
}

.admin-field.power-select > [role="button"] {
  height: auto;
}

.admin-field.power-select > [role="button"],
.ember-power-select-trigger,
.ember-power-select-trigger--active,
.ember-power-select-trigger:focus {
  border: $input-borders;
}


////////////
// #MODALS
///////////

.modal li + li {
  margin-top: 0;
}

.admin-form [class*="col-"] + [class*="col-"] {
  padding-left: 0;
}


.crm, .crm-company, .crm-person, .crm-well {
  margin-right: 0;
}

.log__company__image {
  margin: 0;
}

.tags {
  overflow: auto;
}

.tag {
  white-space: nowrap;

  > a {

    @include hocus() {
      color: $color-white;
      text-decoration: none;
    }
  }
}


.c-order-logs__item-content + .c-order-logs__item-content {
  margin-left: $space-unit !important;
}

.deploy-test {
  color: red;
}
