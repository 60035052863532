.c-card {
  padding: $space-unit;
  border-radius: $global-radius;
  background: $color-gradient--md;
  box-shadow: $global-box-shadow, inset 0 0 0 .08rem rgba($color-grey-900, 0.1);
}

.c-card-list__item {

  & + & {
    margin-top: $space-unit;
  }
}

.c-card-list__name-link {
  font-size: $text-size-7;
}

.c-card-list__company {
  text-decoration: underline;
  text-transform: uppercase;
  margin-top: $space-xs;
  font-size: $text-size-7;
}

  .c-card-list__company-logo {
    width: $space-md;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: $space-xxs;
  }

.c-card-list__meta {
  margin-top: $space-lg;
  font-size: $text-size-7;
  color: $color-grey-600;
}
  .c-card-list__types {
    display: block;
    margin: $space-xs 0;
  }

  .c-card-list__date {
    margin-left: auto;
  }

// Manager Dashboard styles

.c-card-container--manager-dashboard {
  background-color: $color-brand-primary-0;
  border-radius: $global-radius;
  box-shadow: inset 0 0 $space-xs rgba($color-brand-primary-800, 0.3);

  > *:first-child {
    background-color: $color-white;
    padding: $space-unit $space-xs 0 0;
    box-shadow: $space-xxs 0 $space-xxs -#{$space-xxs} rgba($color-brand-primary-800, 0.3);
  }

  > * {
    padding: $space-unit 0 0 $space-xs;
  }
}


.c-card-list--manager-dashboard {
  max-height: rem(900);
  padding: 0 rem(1);
  overflow-y: scroll;

  &::after {
    display: block;
    height: rem(4);
    content: '';
  }
}



////////////////////////
// Big link card styles
///////////////////////

.c-card--big-link__item {
  transition: $global-transition;

  @include hocus() {
    flex-basis: 20%;
  }
}

.c-card--big-link {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-width: rem(180);
  min-height: calc(10rem + 2vw);
  padding: 0;
  color: $color-white;
  text-decoration: none;
  background: $color-brand-primary;

  @include hocus() {
    color: $color-white;
    background: lighten($color-brand-primary, 5%);
    text-decoration: none;

    .c-card--big-link__description {
      z-index: 1;
      height: auto;
      padding: $space-unit;
    }
  }
}

.c-card--big-link__name {
  color: $color-white;
  font-size: $text-size-7;
  letter-spacing: rem(.5);
  text-align: center;
  text-transform: uppercase;
}

.c-card--big-link__description {
  position: absolute;
  top: 98.6%;
  right: 0;
  left: 0;
  height: 0;
  padding: 0 $space-unit;
  overflow: hidden;
  border-radius: 0 0 $space-xxxs $space-xxxs;
  background-color: darken($color-brand-primary, 10%);
  box-shadow: $global-box-shadow--md;
  color: $color-white;
  line-height: 1.3;
  transition: $global-transition;
}

.c-card--big-link--well {
  background: $color-well-analysis;

  @include hocus() {
    background: lighten($color-well-analysis, 5%);
  }

  .c-card--big-link__description {
    background-color: darken($color-well-analysis, 30%);
  }
}

.c-card--big-link--inventory {
  background: $color-current-inventory;

  @include hocus() {
    background: lighten($color-current-inventory, 5%);
  }

  .c-card--big-link__description {
    background-color: darken($color-current-inventory, 30%);
  }
}

.c-card--big-link--history {
  background: $color-order-history;

  @include hocus() {
    background: lighten($color-order-history, 5%);
  }

  .c-card--big-link__description {
    background-color: darken($color-order-history, 30%);
  }
}

.c-card--big-link--inspection {
  background: $color-inspection-reports;

  @include hocus() {
    background: lighten($color-inspection-reports, 5%);
  }

  .c-card--big-link__description {
    background-color: darken($color-inspection-reports, 30%);
  }
}

.c-card--big-link--reporting {
  background: $color-reporting;

  @include hocus() {
    background: lighten($color-reporting, 5%);
  }

  .c-card--big-link__description {
    background-color: darken($color-reporting, 20%);
  }
}
