.c-comments__container {
  padding-top: $space-unit;
  border-top: rem(1.5) solid $color-grey-400;
}

  .c-comments__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

    .c-comments__title {
      font-weight: 600;
      font-size: $text-size-7;
      color: $color-grey-600;
    }

// Single comment

  .c-comments__single {
    max-width: 90ch;
    list-style-type: none;

    & + & {
      margin-top: $space-sm;
      padding-top: $space-unit;
      border-top: rem(1) solid $color-grey-300;
    }

    @include hocus {

      .c-comments__single-delete {
        opacity: 1;
      }
    }
  }

    .c-comments__single-header {
      display: flex;
      align-items: center;
      font-size: $text-size-7;
    }

      .c-comments__single-date {
        color: $color-grey-600;
        margin-left: $space-xs;
      }

      .c-comments__single-delete {
        border: 0;
        box-shadow: none;
        opacity: 0;
        transition: $global-transition;
        padding: 0;
        margin-left: auto;
        min-height: auto;

        @include acthocus() {
          padding: 0;
          background-color: transparent;
          color: $color-danger-800;
          box-shadow: none;
        }

        @media (hover: none) {
          opacity: 1;
        }
      }


main + .c-comments__container {
  margin-top: $space-md;
}
