.emberTagInput {
  display: flex;
  align-items: center;
}

  .emberTagInput-tag {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    padding: $space-xxxs 0 $space-xxxs $space-xs;
    border-radius: $global-radius;
    background-color: $color-brand-primary-900;
    font-size: $text-size-7;
  }

  .emberTagInput-remove {
    padding: 0 $space-xs;
    color: $color-white;
    line-height: 1.8;
    cursor: pointer;
    opacity: 0.6;
    transition: $global-transition;

    &::before {
      content: '×';
    }

    &:hover {
      opacity: 1;
      color: $color-white;
      text-decoration: none;
    }
  }

  .emberTagInput-input {
    font-size: $text-size-7;
    height: auto;
  }
