.o-sub-details {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  @include text-preset-8;

  strong {
    opacity: 0.7;
  }
}
