.c-status-indicator {
  display: inline-block;
  text-align: center;
  font-size: $text-size-7;
  padding: $space-xxxs $space-xs;
  border-radius: $global-radius;
  background-color: $status--pending-bg;
  color: $status--pending-color;
  letter-spacing: rem(0.5);
  text-transform: uppercase;
  @include font-weight(normal);
  align-self: center;

  @include hocus() {
    cursor: help;
  }

  &.completed {
    background-color: $status--completed-bg;
    color: $status--completed-color;
  }

  &.cancelled {
    background-color: $status--canceled-bg;
    color: $status--canceled-color;
  }
}
