.c-modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-y: scroll;
  background-color: rgba($color-brand-secondary-1000, 0.7);
}

  .c-modal__container {
    width: 90vw;
    margin: auto;
    padding: $space-md;
    border-radius: $global-radius;
    background-color: $color-white;

    @include mq(600px) {
       width: 50vw;
    }
  }

  .c-modal__header {
    display: flex;
    align-items: center;
    padding-bottom: $space-unit;
    border-bottom: rem(1) solid $color-grey-500;
  }

    .c-modal__btn-close {
      min-width: auto;
      min-height: auto;
      margin-left: auto;
      padding: 0;
      border: 0;
      background: transparent;
      box-shadow: none;
      color: $color-text;
      font-size: $text-size-3;

      @include acthocus() {
        padding: 0;
        background: transparent;
        box-shadow: none;
        color: lighten($color-text, 20%);
      }
    }

.c-modal--side {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  width: rem(308);
  min-height: 100vh;
  margin: 0;
  padding: $space-unit 0 $space-unit $space-xl;
  transform: translateX(rem(270));
  transition: $global-transition;
  border-left: rem(1) solid $color-grey-200;
  background-color: $color-grey-100;
  cursor: pointer;

  > * > * {
    margin-top: $space-unit;
  }

  &:hover {
    border-color: $color-grey-300;
    background-color: $color-brand-primary-0;
    box-shadow: rem(-2) 0 rem(1) 0 rgba($color-grey-900, 0.2);
  }

  &.is-open {
    padding: $space-lg;
    transform: translateX(0);
    border: 0;
    border-left: rem(1) solid $color-grey-300;
    background-color: $color-grey-0;
    box-shadow: rem(-3) 0 rem(2) 0 rgba($color-grey-900, 0.2);
    cursor: default;

    .c-modal--side__close {
      display: block;
      content: '×';
      position: absolute;
      top: 0;
      right: rem(308);
      width: rem(40);
      height: rem(40);
      margin: $space-xs;
      padding: 0 $space-xs;
      border: 0;
      border: rem(1) solid $color-grey-300;
      border-radius: 50%;
      background: none;
      background: $color-btn-background;
      font-size: 2rem;
      line-height: 1;
      cursor: pointer;

      &:hover {
        background: $color-btn-background-hover;
        color: darken($color-btn, 10%);
      }
    }

    > .c-modal--side__title {
      position: static;
      transform: none;
      font-size: $text-size-5;
      letter-spacing: normal;
      text-transform: capitalize;
    }
  }

}

  .c-modal--side__title {
    position: sticky;
    top: 0;
    transform: translate(rem(-164), 25vh) rotate(-90deg);
    font-size: $text-size-7;
    letter-spacing: rem(1);
    text-transform: uppercase;
    @include text-preset-4--sm;
  }

  .c-modal--side__close {
    display: none;
  }
