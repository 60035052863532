.c-remaining-items {
  background-color: $color-brand-primary-100;
  border-radius: $global-radius;
  padding: rem(1) $space-xxs;
  color: $color-grey-700;

  &.remaining {
    background-color: $color-danger-100;
  }
}

  .c-remaining-items__number {
    color: $color-success-800;

    .remaining > & {
      color: $color-danger-800;
    }
  }
