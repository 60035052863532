.c-facility-orders {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(rem(400), 1fr));
  grid-gap: $space-xl;
}

  .c-facility-orders__header {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    padding: $space-xs 0;

    > .c-btn:last-child {
      justify-self: flex-end;
    }
  }

  .c-facility-orders__indicators {
    padding: $space-lg 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    border-radius: $global-radius;
    background: $color-gradient;
    box-shadow: $global-box-shadow__inset--solid,
                $global-box-shadow;
  }

    .c-facility-orders__indicators__item {
      text-align: center;
      flex: 1;
    }

      .c-facility-orders__indicators__item-number {
        margin: 0;
        font-weight: 900;
        font-size: $text-size-4;
      }

      .c-facility-orders__indicators__item-text {
        margin: 0;
      }
