.o-icon {
  fill: currentColor;
  width: $space-unit;
  height: $space-unit;
  min-width: $space-unit;
  min-height: $space-unit;
  margin-right: $space-xxxs;
  vertical-align: bottom;
}

  .o-icon--sm {
    width: rem(10);
    height: rem(10);
  }

  .o-icon--md {
    width: $space-md;
    height: $space-md;
    min-width: $space-md;
    min-height: $space-md;
  }

  .o-icon--lg {
    width: $space-lg;
    height: $space-lg;
    min-width: $space-lg;
    min-height: $space-lg;
  }

  .o-icon--display {
    width: rem(64);
    height: rem(64);
  }

.o-icon__table {
  opacity: 0.7;
}

.c-btn--lg {

  > .o-icon {
    fill: currentColor;
    width: rem(18);
    height: rem(18);
  }
}

.c-btn--icon--hidden-text {

  .c-btn--icon__text {
    transform: translateX(rem(40));
    background-color: $color-white;
    opacity: 0;
    transition: $global-transition;
  }

  @include hocus() {
    .c-btn--icon__text {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
