.c-input {
  width: 100%;
  height: $input-height;
  padding: $space-xs;
  border: $input-borders;
  border-radius: $global-radius;
}

.c-textarea {
  width: 100%;
  padding: $space-xs;
  border: $input-borders;
  border-radius: $global-radius;
}


.c-input--sm {
  width: auto;
  height: auto;
}

.c-input--btn {
  width: auto;
  height: auto;
  min-height: rem(36);
  padding: $space-sm $space-xs;
  border: rem(1) solid $color-btn-border;
  border-right: 0;
  border-radius: $global-radius 0 0 $global-radius;
  font-size: rem(14);

  + .c-btn {
    border-radius: 0 $global-radius $global-radius 0;
  }
}

// RANGE INPUT

.c-input-range {
  -webkit-appearance: none;
  width: 100%;
  height: auto;
  padding: $space-xs 0;
  border: 0;
}

  .c-input-range--age {
    padding: rem(6) 0 rem(2);
    min-height: rem(28);
  }

.c-input-range__value {
  font-weight: 600;
}

.c-input-range__extra {
  display: flex;
  justify-content: space-between;
  margin-top: -#{$space-xs};
  color: $color-grey-600;
  font-size: $text-size-7;
}

// INPUT WITH ADD NEW BUTTON
.c-input--add-new {
  display: flex;
  flex-direction: column;

  > .c-btn {
    margin-left: auto;
    padding: $space-xxxs 0 0;
    font-size: $text-size-8;
    min-height: auto;
  }
}



// Cross-Browser range styles

.c-input-range:focus {
  outline: none;
}
.c-input-range::-webkit-slider-runnable-track {
  width: 100%;
  height: rem(6);
  border: 0 solid rgba(0, 0, 0, 0);
  border-radius: rem(50);
  background: rgba($color-grey-400, 0.78);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  cursor: pointer;
}
.c-input-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: rem(20);
  height: rem(20);
  margin-top: rem(-7);
  border: rem(1) solid rgba(0, 0, 0, 0.1);
  border-radius: rem(50);
  background: $color-grey-0;
  box-shadow: rem(1) rem(1) rem(3) rgba(0, 0, 0, 0.5), 0 0 rem(1) rgba(13, 13, 13, 0.5);
  cursor: pointer;
}
.c-input-range:focus::-webkit-slider-runnable-track {
  background: rgba(234, 234, 234, 0.78);
}
.c-input-range::-moz-range-track {
  width: 100%;
  height: rem(6);
  border: 0 solid rgba(0, 0, 0, 0);
  border-radius: rem(50);
  background: rgba($color-grey-400, 0.78);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
  cursor: pointer;
}
.c-input-range::-moz-range-thumb {
  width: rem(20);
  height: rem(20);
  border: rem(1) solid rgba(0, 0, 0, 0.1);
  border-radius: rem(50);
  background: $color-grey-0;
  box-shadow: rem(1) rem(1) rem(3) rgba(0, 0, 0, 0.5), 0 0 rem(1) rgba(13, 13, 13, 0.5);
  cursor: pointer;
}
.c-input-range::-ms-track {
  width: 100%;
  height: rem(6);
  border-color: transparent;
  background: transparent;
  color: transparent;
  cursor: pointer;
}
.c-input-range::-ms-fill-lower {
  border: 0 solid rgba(0, 0, 0, 0);
  border-radius: rem(50);
  background: rgba(122, 122, 122, 0.78);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
}
.c-input-range::-ms-fill-upper {
  border: 0 solid rgba(0, 0, 0, 0);
  border-radius: rem(50);
  background: rgba($color-grey-400, 0.78);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.c-input-range::-ms-thumb {
  width: rem(20);
  height: rem(20);
  height: rem(6);
  border: rem(1) solid rgba(0, 0, 0, 0.1);
  border-radius: rem(50);
  background: $color-grey-0;
  box-shadow: rem(1) rem(1) rem(3) rgba(0, 0, 0, 0.5), 0 0 rem(1) rgba(13, 13, 13, 0.5);
  cursor: pointer;
}
.c-input-range:focus::-ms-fill-lower {
  background: rgba($color-grey-400, 0.78);
}
.c-input-range:focus::-ms-fill-upper {
  background: rgba($color-grey-400, 0.78);
}


// Quantity fix column input

.c-input--qty {
  width: rem(100);
  text-align: center;
}
