.c-input--power-select {
  min-height: $input-height;

  .ember-power-select-trigger {
    position: relative;
    border-radius: $global-radius;
    overflow-x: hidden;
    text-overflow: ellipsis;
    min-height: $input-height;
    padding: $space-xxs $space-md 0 $space-xs;

    .ember-power-select-trigger--active,
    .ember-power-select-trigger:focus {
      border: $input-borders;
    }
  }

  .ember-text-field {
    width: 100%;
  }

  .ember-power-select-multiple-options {
    display: flex;
    align-items: center;
    line-height: 2.3;
    overflow-x: auto;
  }

    .ember-power-select-multiple-option {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      padding: 0 0 0 $space-xs;
      border-radius: $global-radius;
      background-color: $color-brand-primary-900;

      + .ember-power-select-multiple-option {
        margin-left: $space-xxxs;
      }
    }

      .option-name {
        padding-bottom: rem(1);
        color: $color-white;
        font-size: $text-size-7;
        white-space: nowrap;
      }

      .ember-power-select-multiple-remove-btn {
        padding: 0 $space-xs;
        color: $color-white;
        line-height: 1.8;
        cursor: pointer;
        opacity: 0.6;
        transition: $global-transition;

        &:hover {
          opacity: 1;
        }
      }

    .ember-power-select-trigger-multiple-input {
      height: auto;
      border: 0;
      padding: 0;
      width: 100%;
    }

    .ember-power-select-trigger-multiple-input:focus {
      margin: 0;
      padding: 0;
    }

    .ember-power-select-status-icon {
      border-width: rem(7) rem(5) 0;
      border-color: $color-grey-400 transparent transparent;
      right: $space-xs;
    }

    .ember-power-select-placeholder,
    .ember-power-select-selected-item {
      margin: 0;
      padding: 0 $space-unit 0 0;
    }

    .ember-power-select-placeholder {
      line-height: 2.2;
    }

    .ember-power-select-selected-item {
      padding: $space-xxxs $space-xs $space-xxxs 0;
    }
}

.c-input--power-select--sm {
  min-height: auto;

  .ember-power-select-trigger {
    height: auto;
    width: 100%;
    padding: 0 $space-xs;
    min-height: auto;
    display: flex;
    align-items: center;
  }

  .ember-text-field {
    height: auto;
    padding: $space-xs 0 $space-xs $space-xs;
  }

  .ember-power-select-placeholder,
  .ember-power-select-selected-item {
    margin: 0;
    padding: 0 $space-unit 0 0;
  }

  .ember-power-select-selected-item {
    padding: 0 $space-unit 0 $space-xs;
  }
}
