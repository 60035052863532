///*----------------------------------*\
//  #SPACINGS
//\*----------------------------------*/

$space-unit:  1rem;
$space-xxxxs: 0.125 * $space-unit;
$space-xxxs:  0.25 * $space-unit;
$space-xxs:   0.375 * $space-unit;
$space-xs:    0.5 * $space-unit;
$space-sm:    0.75 * $space-unit;
$space-md:    1.5 * $space-unit;
$space-lg:    2 * $space-unit;
$space-xl:    3.5 * $space-unit;
$space-xxl:   5.5 * $space-unit;
$space-xxxl:  8.5 * $space-unit;
$space-xxxxl: 13.5 * $space-unit;
