.c-accordion {
  list-style-type: none;
}

  .c-accordion__item {
    position: relative;
    counter-increment: step-counter;

    &::before {
      z-index: 10;
      position: absolute;
      padding: $space-xxxs 0 0 $space-xxs;
      @include text-preset-4--sm;
      color: $color-brand-secondary-900;
      font-variant-numeric: lining-nums;
      font-weight: 900;
      content: counter(step-counter)'-';
    }

    & + & {
      margin-top: $space-xs;
    }
  }

  .c-accordion__item--no-counter {
    counter-increment: none;

    &::before {
      content: none;
    }

    .c-accordion__header {
      padding: $space-xxxs $space-xxxs $space-xxxs $space-xxs;
    }
  }

    .c-accordion__header {
      display: block;
      position: relative;
      width: 100%;
      padding: $space-xxxs $space-xxxs $space-xxxs rem(26);
      border: 0;
      border-radius: $global-radius;
      background-color: $color-grey-200;
      text-align: left;
      cursor: pointer;
      transition: $global-transition;

      @include hocus() {
        background-color: darken($color-grey-200, 5%);
      }

      &::after {
        display: block;
        position: absolute;
        top: 35%;
        right: $space-md;
        width: $space-xs;
        height: $space-xs;
        transform: rotate(135deg);
        border-top: rem(2) solid;
        border-left: rem(2) solid;
        border-color: rgba($color-grey-600, 0.8);
        content: '';
      }

      &.is-open {
        background: lighten($color-grey-200, 2%);

        &::after {
          top: 30%;
          transform: rotate(-135deg);
          border-color: $color-grey-600;
        }
      }
    }

    // ACCORDION PLUGIN
    .is-active {

      .c-accordion__header {
        background: lighten($color-grey-200, 2%);
        
        &::after {
          top: 30%;
          transform: rotate(-135deg);
          border-color: $color-grey-600;
        }
      }
    }

      .c-accordion__header-title {
        @include text-preset-4--sm;
        color: $color-brand-secondary-900;
      }

    .c-accordion__content {
      display: none;
      padding: $space-unit $space-xs;

      > * + * {
        margin-top: $space-unit;
      }

      .is-open + & {
        display: block;
      }

      > * {
        min-width: 100%;
      }
    }
