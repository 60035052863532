.c-inventory-progress__bar::before {
 background-color:#dcdcdc
}
.c-inventory-progress__bar.c-inventory-progress__bar--yellow-band::before {
 background-color:#f8e71c
}
.c-inventory-progress__bar.c-inventory-progress__bar--single-yellow-ws::before {
 background:repeating-linear-gradient(45deg,#f8e71c,#f8e71c 1px,#fff 2px,#fff 4px)
}
.c-inventory-progress__bar.c-inventory-progress__bar--blue-band::before {
 background-color:#098fcd
}

.c-inventory-progress__bar.c-inventory-progress__bar--green-band::before {
 background-color:#2b7a19
}

.c-inventory-progress__bar.c-inventory-progress__bar--red-band::before {
 background-color:red
}

.c-inventory-progress__bar.c-inventory-progress__bar--single-white-ws::before {
 background:repeating-linear-gradient(45deg,#fff,#fff 1px,#dcdcdc 4px,#dcdcdc 4px)
}
.c-inventory-progress__bar.c-inventory-progress__bar--used-uninspected::before {
 background-color:#979797
}

.c-inventory-progress__bar.c-inventory-progress__bar--inspected-1::before {
 background-color:#f5a623
}

.c-inventory-progress__bar.c-inventory-progress__bar--reject::before {
 background-color:#979797
}

.c-inventory-progress__bar.c-inventory-progress__bar--new::before {
 background-color:#85a82d
}
