///*----------------------------------*\
//  #GLOBAL SETTINGS
//\*----------------------------------*/

// Border radius
$global-radius:                                  $space-xxxs;


// Transitions
$global-transition:                              all 0.3s ease;
$global-transition--fast:                        all 0.1s ease;
$global-transition--slow:                        all 0.5s ease;


// Box shadows
$global-box-shadow--light:                       0 0.0625rem 0 0 rgba($color-grey-900, 0.05);
$global-box-shadow:                              0 0.0625rem 0.0625rem 0 rgba($color-grey-900, 0.2);
$global-box-shadow--md:                         0 0.125em 0.188rem 0 rgba($color-grey-900, 0.4);
$global-box-shadow--long:                        0 0.125em 0.188rem 0 rgba($color-grey-900, 0.4),
                                                 0 .5em .188rem 0 rgba($color-grey-900, 0.2),
                                                 0 .75em .3rem 0 rgba($color-grey-900, 0.1);
$global-box-shadow__inset:                       inset 0.0625rem 0.0625rem 0.0625rem 0 rgba($color-grey-900, 0.07);
$global-box-shadow__inset--solid:                inset 0 0 0 0.0625rem rgba($color-grey-900, 0.07);
$global-box-shadow__inset--md:                  inset 0.0625rem 0.0625rem 0.0625rem 0 rgba($color-grey-900, 0.2);


// Input borders
$input-borders:                                  0.0700rem solid $color-grey-400;
$input-height:                                   3.125rem; //50px
