.o-display--lg {
  @include text-preset-1;
}

.o-display--md {
  @include text-preset-2;
}

.o-display--sm {
  @include text-preset-3;
}

.o-heading {
  @include text-preset-4;
}

.o-heading--sm {
  @include text-preset-4--sm;
}

.o-subheading {
  @include text-preset-5;
}

.o-label {
  @include text-preset-6;
  color: $color-grey-600;
}

.o-body-text {
  @include text-preset-7;
}

.o-body-text--sm {
  @include text-preset-8;
}
