.c-tabbed-content__tabs {
  display: inline-block;
}

  .c-tabbed-content__tab {
    margin: 0;
    margin-bottom: rem(-1);
    padding: $space-xs $space-unit;
    border: 0;
    border: rem(1) solid $color-grey-300;
    background: $color-grey-0;
    letter-spacing: rem(1);
    text-transform: uppercase;
    cursor: pointer;
    opacity: 0.7;

    &.is-active {
      border-top: $space-xxxs solid $color-brand-secondary-300;
      border-bottom: rem(1) solid $color-white;
      background: $color-white;
      font-weight: 600;
      cursor: default;
      opacity: 1;
    }
  }

  .c-tabbed-content__tab-search {
    padding: rem(2);
    transform: translateY($space-xs);

    .admin-field.power-select > [role="button"] {
      min-width: rem(150);
      height: rem(24);
      margin: 0;
    }

    & + .c-tabbed-content__tab-add {
      padding: $space-xs $space-xxxs;
      color: darken($color-text, 10%);
      font-weight: 600;

      svg {
        margin: 0 0 rem(-1) $space-unit;
      }
    }
  }

  .c-tabbed-content__tab-add {
    transform: translateY(rem(3));
    border: 0;
    background-color: transparent;
    opacity: 1;
  }

.c-tabbed-content__content {
  margin: 0;
  padding: $space-xs $space-md 0;
  border: rem(1) solid $color-grey-300;
  border-radius: 0 $space-xxxs $space-xxxs;
}
