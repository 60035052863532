.c-list--inventory {
  margin-right: -#{$space-md};
  margin-left: -#{$space-md};
  padding: $space-md;
  border-radius: $global-radius;
  background-color: $color-grey-100;
}

.c-list--inventory__item {
  position: relative;
  list-style-type: none;

  .c-table__wrapper {
    display: none;
  }

  &.is-open {
    position: relative;
    display: grid;

    .c-inventory-progress__bar:first-child::before,
    .c-inventory-progress__bar:last-child::before {
      border-radius: 0;
    }

    .c-inventory-progress__info {
      display: none;
    }

    .c-table__wrapper {
      display: block;
    }

    .c-table__header__item:first-child,
    .c-table__header__item:last-child {
      border-radius: 0;
    }

    .c-list--inventory__item__qty {
      margin-right: $space-xl;
    }
  }
}

  .c-list--inventory__item__header {
    display: flex;
    justify-content: space-between;
    padding: $space-sm $space-xs;
    border: rem(0.5) solid $color-grey-300;
    border-radius: $global-radius $global-radius 0 0;
    background-color: $color-white;
    box-shadow: $global-box-shadow;
    cursor: pointer;
    transition: $global-transition;

    @include hocus() {
      box-shadow: 0 rem(1) rem(1.5) rem(-1) rgba($color-grey-900, 0.4),
                  0 rem(2) rem(1.5) rem(-1) rgba($color-grey-800, 0.4),
                  0 rem(3) rem(2) rem(-1) rgba($color-grey-800, 0.3),
                  0 rem(4) rem(2.5) rem(-1) rgba($color-grey-800, 0.25),
                  0 rem(5) rem(3.5) rem(-1) rgba($color-grey-700, 0.2),
                  0 rem(6) rem(4.5) 0 rgba($color-grey-700, 0.1),
                  0 rem(7) rem(5.5) 0 rgba($color-grey-600, 0.1),
                  0 rem(8) rem(6.5) 0 rgba($color-grey-600, 0.05);
    }
  }

  .c-list--inventory__item__header--transfer {
    display: grid;
    grid-template-columns: 25% 20% 2fr auto;
    grid-gap: $space-unit;
    grid-row-gap: $space-xs;
    border-radius: $global-radius;

    @include hocus() {
      box-shadow: 0 rem(1) rem(1.5) rem(-1) rgba($color-grey-900, 0.2),
                  0 rem(1.5) rem(1.5) rem(-1) rgba($color-grey-800, 0.2),
                  0 rem(2) rem(2) rem(-1) rgba($color-grey-800, 0.15),
                  0 rem(2.5) rem(2.5) rem(-1) rgba($color-grey-800, 0.1),
                  0 rem(3.5) rem(3.5) rem(-1) rgba($color-grey-700, 0.1),
                  0 rem(4.5) rem(4.5) 0 rgba($color-grey-700, 0.05),
                  0 rem(5.5) rem(5.5) 0 rgba($color-grey-600, 0.05),
                  0 rem(6.5) rem(6.5) 0 rgba($color-grey-600, 0.02);
    }

    .is-open > & {
      border-radius: $global-radius $global-radius 0 0;
    }
  }

    .c-list--inventory__item__product-name {
      grid-column: 2;
    }

    .c-list--inventory__item__product-size {
      grid-column: 3;
    }

    .c-list--inventory__item__title,
    .c-list--inventory__item__qty-number {
      font-weight: 600;
    }

    .c-list--inventory__item__title {
      grid-column: 1;
    }

    .c-list--inventory__item__qty {
      grid-column: 4;
      grid-row: 1;
    }

    .c-list--inventory__item__qty-number {
      color: $color-brand-secondary-700;
    }

  .c-inventory-progress__container {
    display: flex;
  }

    .c-inventory-progress__bar {
      transition: $global-transition;

      &::before {
        display: block;
        width: 100%;
        height: rem(4);
        background-color: red;
        content: '';
      }

      &:first-child {

        &::before {
          border-radius: 0 0 0 $global-radius;
        }
      }

      &:last-child {

        &::before {
          border-radius: 0 0 $global-radius 0;
        }
      }

      &:only-child {

        &::before {
          border-radius: 0 0 $global-radius $global-radius;
        }
      }
    }

      .c-inventory-progress__info {
        overflow: hidden;
        font-size: rem(14);
        white-space: nowrap;
      }

      .c-inventory-progress__condition {
        display: inline-block;

        &::first-letter {
          text-transform: uppercase;
        }
      }

    .c-inventory-progress__bar--8,
    .c-inventory-progress__bar--7,
    .c-inventory-progress__bar--6,
    .c-inventory-progress__bar--5,
    .c-inventory-progress__bar--4,
    .c-inventory-progress__bar--3,
    .c-inventory-progress__bar--2,
    .c-inventory-progress__bar--1,
    .c-inventory-progress__bar--0 {
      min-width: $space-unit;

      &::before {
        background-color: cyan;
      }

      > * {
        width: rem(1);
        opacity: 0;
        transition: $global-transition;
      }

      @include hocus() {
          min-width: 20%;

          > * {
            width: auto;
            opacity: 1;
        }
    }
  }

    @for $i from 1 through 100 {
    .c-inventory-progress__bar--#{$i} {
      width: 0% + $i;
    }
  }

  .c-table--inventory__configure-columns {
    position: absolute;
    top: 0;
    right: $space-unit;
    padding: 0;

    .o-icon {
      fill: $color-grey-800;
    }
  }
