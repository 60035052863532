$collapse-border: rem(1.5) solid $color-grey-300;

.c-collapse-container {
  position: relative;
}

  .c-collapse-container__heading {
    position: relative;
    padding: $space-xs $space-unit;
    border: $collapse-border;
    border-radius: $global-radius;
    border-color: transparent;
    background-color: $color-brand-secondary-100;
    color: $color-brand-secondary-900;
    @include text-preset-6;
    cursor: pointer;
    transition: $global-transition;

    @include hocus(){
      background-color: darken($color-brand-secondary-100, 5%);
    }

    &::after {
      display: block;
      position: absolute;
      top: 40%;
      right: $space-unit;
      width: $space-xs;
      height: $space-xs;
      transform: rotate(135deg);
      border-top: rem(1.8) solid;
      border-left: rem(1.8) solid;
      border-color: rgba($color-brand-secondary-900, 0.8);
      content: '';
    }

    &.is-open {
      border: $collapse-border;
      border-bottom: 0;
      border-radius: $global-radius $global-radius 0 0;

      &::after {
        top: 36%;
        transform: rotate(-135deg);
      }

      + .c-collapse-container__content,
      + .c-collapse-container__content + * {
        display: block;

        .c-filter-box {
          border: $collapse-border;
          border-top: 0;
          border-radius: 0 0 $global-radius $global-radius;
        }
      }
    }
  }

  .c-collapse-container__content,
  .c-collapse-container__update-filters {
    display: none;
  }

    .c-collapse-container__update-filters {
      z-index: 50;
      position: absolute;
      top: rem(262);
      left: $space-md;
    }

    .c-filter-box--transaction + .c-collapse-container__update-filters  {
      top: rem(356);
    }
