.c-field {

  > * + * {
    margin-top: $space-xxs;
  }
}

  .c-field__label {
    @include text-preset-6;
    color: $color-grey-600;
  }
