.c-facility-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: rem(220);
  border-radius: $global-radius;
  background: $color-gradient;
  box-shadow: $global-box-shadow__inset--solid,
              $global-box-shadow;
  color: $color-grey-600;
  text-align: center;

  .o-icon {
    width: rem(80);
    height: rem(56);
    margin: 0;
    color: $color-link-hover;
    opacity: 0.6;
    transition: $global-transition;
  }

  @include hocus() {
    transform: translateY(rem(-4));
    border-bottom: $space-xs solid;
    background: $color-gradient--md;
    box-shadow: $global-box-shadow__inset--solid,
                $global-box-shadow--long;
    text-decoration: none;

    .o-icon {
      transform: scale(1.1);
      opacity: 1;
    }

    .c-facility-card__name {
      color: $color-brand-primary-600;
    }
  }
}

  .c-facility-card__name {
    font-weight: 600;
    margin-top: $space-unit;
  }
