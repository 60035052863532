a {
  color: $color-link;
  position: relative;
  text-decoration: none;
  transition: $global-transition;

  @include hocus() {
    color: $color-link-hover;
    text-decoration: underline solid rem(1.5);
    text-underline-offset: $space-xxxs;
  }
}
