.c-inventory__total-items {
  color: $color-brand-secondary-700;
  font-weight: 600;
  font-size: rem(54);
}

.c-inventory__type-toggle {
  height: $input-height;
}

.c-filter-box {
  position: relative;
  background-color: $color-white;
  z-index: 20;
}

.c-filter-box__trigger {
  position: sticky;
  top: 0;
  z-index: 10;
  display: block;
  font-size: $text-size-8;
  width: 100%;
  padding: $space-xxxs 0 $space-xxs;
  text-align: center;
  cursor: pointer;
  background-color: $color-brand-primary-100;
  color: $color-brand-primary-700;
  border: rem(1) solid $color-brand-primary-300;
  border-radius: $global-radius;
  transition: $global-transition;
  margin-top: rem(88) !important; // #Rewrite
  margin-bottom: rem(-88) !important; // #Rewrite

  @include hocus() {
    background-color: lighten($color-brand-primary-100, 1%);
    color: $color-brand-primary-700;
    box-shadow: $global-box-shadow;
  }
}

.c-filter-box__trigger-input {
  display: none;
  margin-top: rem(88) !important; // #Rewrite
  margin-bottom: rem(-88) !important; // #Rewrite

  &:checked {
    + .c-filter-box__trigger {
      background-color: lighten($color-brand-primary-100, 4%);
      color: $color-brand-primary-800;
      border: rem(1) solid $color-brand-primary-300;
      border-bottom: 0;
      border-radius: $global-radius $global-radius 0 0;
      margin: rem(84) 0 rem(-83) !important; // #Rewrite
    }

    + * + * {
      margin: 0 0 rem(30);
    }

    + * + * + .c-filter-box {
      position: sticky;
      top: rem(22);
      border-radius: 0 0 $global-radius $global-radius;
      border-top-width: rem(0.5);
      padding: $space-xs $space-md;
      grid-row-gap: 0;
      box-shadow: $global-box-shadow--long;

      .c-inventory__total-items {
        font-size: rem(40);
      }

      .c-inventory__type-toggle {
        align-items: start;
        height: auto;
      }

        .c-btn {
          height: auto;
        }

      .c-input {
        height: auto;
      }
    }
  }
}
