///*----------------------------------*\
//  #TYPOGRAPHY
//\*----------------------------------*/

// Import Open Sans font
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,800&display=swap');

// Mixins to help generating typographical rules.


// Shorthand onto specific font-stacks.
@mixin font-family($family) {

  @if ($family == "heading") {
    font-family: 'Open Sans', sans-serif;
  }

  @elseif ($family == "body") {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }

  @else {
    @warn "#{$family} is not a valid font-family."
  }

}

// Line-height Crop
@mixin lhCrop($line-height) {
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }
}


// Map keywords onto our numbered weights for convenience.

@mixin font-weight($weight) {

  @if ($weight == normal) {
    font-weight: 400;
  }

  @elseif ($weight == bold) {
    font-weight: 600;
  }

  @elseif ($weight == black) {
    font-weight: 800;
  }

  @else {
    @warn "#{$weight} is not a valid font-weight."
  }

}


// Type scale

$text-scale-ratio: 1.2;

$text-size-8:   1rem / 1.5;
$text-size-7:   1rem / $text-scale-ratio;
$text-size-6:   1rem / $text-scale-ratio * $text-scale-ratio;
$text-size-5:   1rem * $text-scale-ratio;
$text-size-4:   1rem * $text-scale-ratio * $text-scale-ratio;
$text-size-3:   1rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio;
$text-size-2:   1rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio;
$text-size-1:   1rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio;



@mixin text-preset-1 {
    font-size: $text-size-1;
    @include font-family(heading);
    @include font-weight(black);
    line-height: 1.2;
}

@mixin text-preset-2 {
    font-size: $text-size-2;
    @include font-family(heading);
    @include font-weight(black);
    line-height: 1.2;
}

@mixin text-preset-3 {
    font-size: $text-size-3;
    @include font-family(heading);
    @include font-weight(black);
    line-height: 1.2;
    @include lhCrop(1.2);
}

@mixin text-preset-4 {
    font-size: $text-size-4;
    @include font-family(heading);
    @include font-weight(bold);
    line-height: 1.2;
}

@mixin text-preset-4--sm {
    font-size: $text-size-5;
    @include font-family(heading);
    @include font-weight(bold);
    line-height: 1.2;
}

@mixin text-preset-5 {
    font-size: $text-size-7;
    @include font-family(heading);
    @include font-weight(bold);
    text-transform: uppercase;
    line-height: 1.2;
    color: $color-grey-700;
}

@mixin text-preset-6 {
    font-size: $text-size-7;
    @include font-family(heading);
    @include font-weight(bold);
    line-height: 1.2;
}

@mixin text-preset-7 {
    font-size: $text-size-6;
    @include font-family(body);
    line-height: 1.4;
    max-width: 80ch;
}

@mixin text-preset-8 {
    font-size: $text-size-7;
    @include font-family(body);
    line-height: 1.5;
    max-width: 90ch;
}
