///*----------------------------------*\
//  #COLORS
//\*----------------------------------*/


// BRAND COLORS
// ------------

// Primary color options
$color-brand-primary-0:      hsl(210, 20%, 96%);
$color-brand-primary-100:    hsl(218, 33%, 93%);
$color-brand-primary-200:    hsl(217, 30%, 82%);
$color-brand-primary-300:    hsl(218, 36%, 70%);
$color-brand-primary-400:    hsl(213, 31%, 57%);
$color-brand-primary-500:    hsl(213, 44%, 40%);
$color-brand-primary-600:    hsl(213, 48%, 30%);
$color-brand-primary-700:    hsl(214, 68%, 23%);
$color-brand-primary-800:    hsl(213, 89%, 18%);
$color-brand-primary-900:    hsl(213, 100%, 14%);


$color-brand-primary:               $color-brand-primary-500;
$color-brand-primary-highlight:     lighten($color-brand-primary, 5%) !default;
$color-brand-primary-shadow:        darken($color-brand-primary,  5%) !default;


// Secondary color options

$color-brand-secondary-0:      hsl(198, 80%, 98%);
$color-brand-secondary-100:    hsl(198, 80%, 90%);
$color-brand-secondary-200:    hsl(198, 80%, 80%);
$color-brand-secondary-300:    hsl(198, 80%, 70%);
$color-brand-secondary-400:    hsl(198, 80%, 60%);
$color-brand-secondary-500:    hsl(198, 80%, 50%);
$color-brand-secondary-600:    hsl(201, 100%, 45%);
$color-brand-secondary-700:    hsl(201, 100%, 35%);
$color-brand-secondary-800:    hsl(201, 100%, 25%);
$color-brand-secondary-900:    hsl(201, 100%, 15%);
$color-brand-secondary-1000:   hsl(201, 100%, 12%);

$color-brand-secondary:             $color-brand-secondary-500;
$color-brand-secondary-highlight:   lighten($color-brand-secondary, 5%);
$color-brand-secondary-shadow:      darken($color-brand-secondary,  5%);


// Tertiary color options

$color-brand-tertiary-100:  hsl(11, 81%, 96%);
$color-brand-tertiary-200:  hsl(7, 80%, 86%);
$color-brand-tertiary-300:  hsl(8, 80%, 76%);
$color-brand-tertiary-400:  hsl(8, 84%, 66%);
$color-brand-tertiary-500:  hsl(8, 84%, 56%);
$color-brand-tertiary-600:  hsl(8, 88%, 46%);
$color-brand-tertiary-700:  hsl(8, 92%, 36%);
$color-brand-tertiary-800:  hsl(8, 100%, 26%);
$color-brand-tertiary-900:  hsl(10, 100%, 16%);

$color-brand-tertiary:             $color-brand-tertiary-500;
$color-brand-tertiary-highlight:   lighten($color-brand-tertiary, 5%);
$color-brand-tertiary-shadow:      darken($color-brand-tertiary,  5%);


// HELPER COLORS
// -------------

$color-white: #fff;

// Grey color options

$color-grey-0:    hsl(0, 0%, 99%);
$color-grey-100:  hsl(0, 0%, 97%);
$color-grey-200:  hsl(0, 0%, 95%);
$color-grey-300:  hsl(0, 0%, 89%);
$color-grey-400:  hsl(0, 0%, 70%);
$color-grey-500:  hsl(0, 0%, 50%);
$color-grey-600:  hsl(213, 5%, 41%);
$color-grey-700:  hsl(229, 6%, 34%);
$color-grey-800:  hsl(217, 10%, 25%);
$color-grey-900:  hsl(216, 18%, 16%);


// Success color options

$color-success-100:  hsl(71, 85%, 89%);
$color-success-200:  hsl(73, 87%, 79%);
$color-success-300:  hsl(75, 77%, 65%);
$color-success-400:  hsl(77, 66%, 54%);
$color-success-500:  hsl(79, 93%, 39%);
$color-success-600:  hsl(81, 93%, 33%);
$color-success-700:  hsl(84, 96%, 28%);
$color-success-800:  hsl(85, 98%, 22%);
$color-success-900:  hsl(87, 96%, 18%);


// Danger color options

$color-danger-0:    hsl(23, 100%, 98%);
$color-danger-100:  hsl(23, 100%, 91%);
$color-danger-200:  hsl(19, 98%, 82%);
$color-danger-300:  hsl(13, 100%, 74%);
$color-danger-400:  hsl(9, 99%, 67%);
$color-danger-500:  hsl(3, 99%, 56%);
$color-danger-600:  hsl(357, 79%, 48%);
$color-danger-700:  hsl(353, 81%, 39%);
$color-danger-800:  hsl(348, 86%, 31%);
$color-danger-900:  hsl(342, 92%, 25%);

// Warning color options

$color-warning-100:  hsl(45, 100%, 90%);
$color-warning-200:  hsl(44, 98%, 80%);
$color-warning-300:  hsl(42, 96%, 70%);
$color-warning-400:  hsl(39, 96%, 62%);
$color-warning-500:  hsl(36, 97%, 51%);
$color-warning-600:  hsl(35, 94%, 43%);
$color-warning-700:  hsl(32, 95%, 36%);
$color-warning-800:  hsl(29, 97%, 29%);
$color-warning-900:  hsl(27, 96%, 23%);


// Section and module colors.

$color-well-analysis:              hsl(190, 60%, 65%);
$color-current-inventory:          hsl(337, 62%, 51%);
$color-order-history:              hsl(160, 50%, 48%);
$color-inspection-reports:         hsl(40, 81%, 52%);
$color-reporting:                  hsl(238, 36%, 42%);


// Reassign literal variables to meaningful ones.

$color-text:                            $color-grey-900;

$color-link:                            $color-brand-secondary-700;
$color-link-hover:                      lighten($color-brand-secondary-700, 5%);

$color-gradient:                        linear-gradient(180deg, $color-white, $color-grey-0);
$color-gradient--md:                    linear-gradient(180deg, $color-white, $color-grey-100);
$color-gradient--primary:               linear-gradient(180deg, $color-white, $color-brand-primary-0);

$color-btn:                             $color-grey-700;
$color-btn-background:                  $color-gradient--primary;
$color-btn-background-hover:            linear-gradient(180deg, $color-brand-primary-0, $color-brand-primary-0);
$color-btn-border:                      rgba($color-grey-400, 0.5);

$color-btn-primary:                     $color-white;
$color-btn-primary-background:          linear-gradient(180deg, $color-brand-secondary-400, $color-brand-secondary);
$color-btn-primary-background-hover:    linear-gradient(180deg, $color-brand-secondary, $color-brand-secondary);
$color-btn-primary-background-active:   linear-gradient(180deg, $color-brand-secondary-shadow, $color-brand-secondary-shadow);
$color-btn-primary-border:              $color-brand-secondary-600;

$color-btn-outline:                     $color-brand-secondary;
$color-btn-outline-background:          transparent;
$color-btn-outline-background-hover:    darken($color-brand-secondary-0, 2%);

$color-btn-danger:                      $color-danger-600;
$color-btn-danger-background:           transparent; // linear-gradient(180deg, $color-danger-500, $color-danger-600)
$color-btn-danger-background-hover:     $color-danger-0;
$color-btn-danger-background-active:    $color-danger-600;
$color-btn-danger-border:               $color-danger-600;


// Status indicator colors
$status--pending-bg:        #ffefa1;
$status--pending-color:     #7e6800;
$status--canceled-bg:       $color-danger-100;
$status--canceled-color:    $color-danger-700;
$status--completed-bg:      lighten($color-success-200, 8%);
$status--completed-color:   $color-success-900;
