.o-hover-tip__container {
  position: relative;
}

  .o-hover-tip {

    @include hocus() {

      > .o-hover-tip__content {
        height: auto;
        padding: $space-xs $space-unit;
        overflow: visible;

        &::before {
          display: block;
        }
      }
    }
  }

    .o-hover-tip__content {
      z-index: 10;
      position: absolute;
      top: calc(100% + #{$space-xs});
      right: 0;
      left: 0;
      height: 0;
      height: 0;
      padding: 0 $space-unit;
      overflow: hidden;
      border-radius: $global-radius;
      background-color: $color-brand-secondary-800;
      box-shadow: $global-box-shadow;
      color: $color-brand-primary-0;

      &::before {
        content:'▲';
        display: none;
        position: absolute;
        top: rem(-12);
        left: $space-xs;
        width: $space-xs;
        height: $space-xs;
        color: $color-brand-secondary-800;
      }
    }
