/* ==========================================================================
   #GRID
   ========================================================================== */

/**
 * Grid layout system.
 * There are plenty more options available to us: explore them below.
 */


/* Default/mandatory classes
   ========================================================================== */

/**
 * 1. Allows us to use the layout object on any type of element.
 * 2. We need to defensively reset any box-model properties.
 * 3. Make the grid container.
 * 4. Adds default grid gutters.
 * 5. Sets a fluid 12 column grid that grows and shrinks to fit inside the grid width.
 */

 @mixin o-grid {
   grid-gap: $space-unit; /* [4] */
   display: grid; /* [3] */
   margin:  0; /* [2] */
   padding: 0; /* [2] */
   list-style: none; /* [1] */
 }

 $global-grid-columns: repeat(12, 1fr);

.o-grid {
  @include o-grid;
  grid-template-columns: $global-grid-columns; /* [5] */

  > * {
    @include span-all-columns; // Children span all columns by default, mobile first approach
  }
}


.o-grid--fix {
  $rts-fix-col: (
    col
  );

  @each $direction in $rts-fix-col {
    @for $rts-grid-columns from 1 through 12 {
      &--#{$rts-grid-columns} {
        grid-template-columns: repeat(#{$rts-grid-columns}, 1fr);

        > * {
          grid-column: auto; // Removes the span all columns approach for auto columns
        }
      }
    }
  }
}


// Flex auto width columns grid

.o-grid-flow {
  // Set the gutter width
  $gutter-width: $space-unit;
  $breakpoint: rem(640);

  display: flex;
  flex-flow: row wrap;
  width: calc(100% + #{$gutter-width});
  margin-right: $gutter-width / -2;
  margin-left:  $gutter-width / -2; // The negative margins allow for nesting of columns

  > * {
    position: relative;
    flex: 1 0 100%; // Columns automatically size themselves
    max-width: 100%; // Fallback for browsers that don't support calc
    max-width: calc(100% - #{$gutter-width});
    min-height: rem(1);
    margin-right: $gutter-width / 2;
    margin-bottom: $gutter-width;
    margin-left: $gutter-width / 2;

    @media only screen and (min-width: $breakpoint) {
      flex-basis: 0;
    }
  }

  &.o-grid__gap--md {
    $gutter-width: $space-lg;
    width: calc(100% + #{$gutter-width});
    margin-right: $gutter-width / -2;
    margin-left:  $gutter-width / -2; // The negative margins allow for nesting of columns

    > * {
      max-width: calc(100% - #{$gutter-width});
      margin-right: $gutter-width / 2;
      margin-bottom: $gutter-width;
      margin-left: $gutter-width / 2;
    }
  }
}

// Auto column grid classes
$grid-auto-sizes: (auto--sm, rem(70)) (auto, rem(100)) (auto--md, rem(165)) (auto--lg, rem(200)) (auto--xl, rem(240));

@each $size, $value in $grid-auto-sizes {
  .o-grid--#{$size} {
    grid-template-columns: repeat(auto-fit, minmax(#{$value}, 1fr));

    > * {
      grid-column: auto; // Removes the span all columns approach for auto columns
    }
  }
}

.o-grid--auto--content {
  grid-template-columns: repeat(auto-fit, minmax(rem(100), max-content));
  align-items: center;

  > * {
    grid-column: auto; // Removes the span all columns approach for auto columns
  }
}

.o-grid--auto--content--0 {
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
  align-items: center;

  > * {
    grid-column: auto; // Removes the span all columns approach for auto columns
  }
}


/* Responsive grid classes
   ========================================================================== */

.o-grid {
  @each $breakpoint in $rts-breakpoints {
    $breakpoint-name: nth($breakpoint, 1);

    @include rts-mq($breakpoint-name) {
      &\@#{$breakpoint-name} {
        @include o-grid;
        grid-template-columns: $global-grid-columns;
      }
    }
  }
}

.o-grid--fix {
 @each $breakpoint in $rts-breakpoints {
   $breakpoint-name: nth($breakpoint, 1);

   @for $rts-grid-columns from 1 through 12 {
     @include rts-mq($breakpoint-name) {
       &--#{$rts-grid-columns}\@#{$breakpoint-name} {
         grid-template-columns: repeat(#{$rts-grid-columns}, 1fr);

         > * {
           grid-column: auto; // Removes the span all columns approach for fix column grid
         }
       }
     }
   }
 }
}


// Auto column grid
@each $size, $value in $grid-auto-sizes {
  @each $breakpoint in $rts-breakpoints {
    $breakpoint-name: nth($breakpoint, 1);

    @include rts-mq($breakpoint-name) {
      .o-grid--#{$size}\@#{$breakpoint-name} {
        @include o-grid;
        grid-template-columns: repeat(auto-fit, minmax(#{$value}, 1fr));

        > * {
          grid-column: auto; // Removes the span all columns approach for auto columns
        }
      }
    }
  }
}




/* Gutter size modifiers
   ========================================================================== */

$gap-sizes: (gap--0, 0) (gap, $space-unit) (gap--s, $space-xs) (gap--md, $space-lg);


.o-grid__gap--0 {
  grid-gap: 0;
}

.o-grid__gap {
  grid-gap: $space-unit;
}

.o-grid__gap--sm {
  grid-gap: $space-xs;
}

.o-grid__gap--md {
  grid-gap: $space-lg;
}

.o-grid__col-gap--md {
  grid-column-gap: $space-lg;
}

.o-grid__row-gap--sm {
  grid-row-gap: $space-xs;
}

// Grid gap responsive classes
@each $gap-size, $value in $gap-sizes {
  @each $breakpoint in $rts-breakpoints {
    $breakpoint-name: nth($breakpoint, 1);

    @include rts-mq($breakpoint-name) {
      .o-grid__#{$gap-size}\@#{$breakpoint-name} {
        grid-gap: #{$value};
      }
    }
  }
}
