.c-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  min-height: $space-xxxl;
  margin-top: $space-unit;
  padding: $space-lg;
  border: rem(1) dashed $color-brand-secondary-600;
  background-color: $color-brand-secondary-0;

  > * + * {
    margin-top: $space-unit;
  }
}

  .c-dropzone__title {
    color: $color-grey-600;
  }
