.c-pagination__list {
  display: flex;
}

  .c-pagination__item {
    margin: 0 $space-xs;

    &:first-child {
      margin-left: 0;
    }
  }

    .c-pagination__link {
      padding: $space-xs;
      border-radius: $global-radius;

      @include hocus() {
        background-color: $color-grey-100;
        text-decoration: none;
      }


      &.is-current {
        color: $color-white;
        font-weight: 600;
        background-color: $color-link;

        @include hocus() {
          cursor: default;
        }
      }
    }
