.u-f-weight-800 {
  font-weight: 800 !important;
}

.u-f-weight-600 {
  font-weight: 600 !important;
}

.u-f-weight-400 {
  font-weight: 400 !important;
}
