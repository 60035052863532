.c-sidebar {
  z-index: 100;
  position: sticky;
  top: 0;
  flex-grow: 1;
  width: 100%;
  padding: $space-unit $space-md $space-md;
  background-color: $color-brand-secondary-1000;
  font-size: $text-size-7;

  @include mq(678px) {
    position: relative;
    padding: $space-lg 0;
    width: rem(190);
  }
}

  .c-sidebar__wrapper {
    display: flex;
    flex-wrap: wrap;

    @include mq(678px) {
      position: -webkit-sticky;
      position: sticky;
      top: $space-lg;
      flex-direction: column;
      min-height: calc(100vh - 4rem);
    }
  }

    .c-sidebar__header {

      @include mq(678px) {
        margin-bottom: $space-lg;
      }
    }

      .c-sidebar__logo {
        display: block;
        text-align: center;
      }

        .c-logo {
          width: rem(50);
          margin-bottom: rem(-8);

          @include mq(678px) {
            width: 100%;
            max-width: rem(100);
            margin-bottom: 0;
          }
        }

        .c-logo__text {
          display: none;

          @include mq(678px) {
            display: block;
          }
        }

    .c-sidebar__nav {
      display: none;
      position: relative;
      width: calc(#{$space-md} * 2 + 100%);
      margin-right: -#{$space-md};
      margin-bottom: $space-unit;
      margin-left: -#{$space-md};
      padding-top: $space-unit;

      @include mq(678px) {
        display: block;
        width: 100%;
        margin: 0 0 $space-xl;
        padding-top: $space-lg;

        &::before {
          position: absolute;
          top: 0;
          left: $space-unit;
          width: calc(100% - 2rem);
          height: rem(1.5);
          background-color: rgba($color-brand-secondary-800, 0.5);
          content: '';
        }
      }
    }

    .c-sidebar__nav__list {
      padding: 0;
    }

      .c-sidebar__nav__item--parent {

        > .c-sidebar__nav__link {
          padding-right: rem(24);

          &::after {
            display: block;
            position: absolute;
            top: 44%;
            right: $space-md;
            width: $space-xxs;
            height: $space-xxs;
            transform: rotate(135deg);
            border-top: rem(1) solid;
            border-left: rem(1) solid;
            border-color: rgba($color-white, 0.8);
            content: '';
          }

          &.is-active {
            border: 0;
            background: lighten($color-brand-secondary-900, 2%);

            &::after {
              top: 38%;
              transform: rotate(-135deg);
              border-color: $color-white;
            }
          }
        }
      }

      .c-sidebar__nav__link {
        display: block;
        position: relative;
        padding: $space-unit $space-md;
        color: rgba($color-white, 0.8);
        text-decoration: none;
        cursor: pointer;
        transition: $global-transition;

        @include hocus() {
          background-color: lighten($color-brand-secondary-1000, 2.5%);
          color: $color-brand-primary-100;
          text-decoration: none;
        }

        &.is-active {
          border-right: rem(4) solid $color-brand-tertiary-600;
          background: linear-gradient(90deg,lighten($color-brand-secondary-900, 2%) 80%, lighten($color-brand-secondary-900, 8%) 100%);
          color: $color-white;
          font-size: rem(14);

          & + .c-sidebar__nav-child {
            display: block;
          }
        }
      }

        .c-sidebar__nav-child {
          display: none;
          padding: $space-sm 0;
          background-color: darken($color-brand-secondary-1000, 2%);
          font-size: $text-size-7;
        }

        .c-sidebar__nav-child__link {
          padding-left: $space-lg;
          background-color: darken($color-brand-secondary-1000, 2%);
          color: $color-brand-primary-100;

          @include acthocus() {
            background: darken($color-brand-secondary-1000, 1%);
          }

          &.is-active {
            background: linear-gradient(90deg, $color-brand-secondary-1000 80%, lighten($color-brand-secondary-1000, 3%) 100%);
            color: $color-white;
            font-size: rem(14);
          }
        }

    .c-sidebar__footer {
      display: none;
      width: calc(#{$space-md} * 2 + 100%);
      margin-right: -#{$space-md};
      margin-left: -#{$space-md};

      @include mq(678px) {
        display: block;
        width: 100%;
        margin: auto 0 0;
      }
    }

      .c-sidebar__username-dropdown {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        margin: 0 $space-unit;
        padding: $space-xs $space-xs;
        border: rem(1) solid lighten($color-brand-secondary-1000, 3%);
        border-radius: $global-radius;
        background-color: darken($color-brand-secondary-1000, 2%);

        @include mq(678px) {
          display: block;
          padding: $space-sm $space-xs;

          @include hocus() {

            .c-sidebar__username {
              opacity: 1;
            }

            .c-sidebar__nav-link__logout {
              transform: translateY(rem(-40));
              opacity: 1;
            }
          }
        }
      }

        .c-sidebar__username {
          color: $color-grey-100;
          font-size: $text-size-7;
          font-weight: 400;
          line-height: 1.5;
          opacity: 0.8;
          transition: $global-transition;

          @include mq(678px) {
            padding-right: $space-unit;
            background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3ccircle cx='12' cy='12' r='1'%3e%3c/circle%3e%3ccircle cx='12' cy='5' r='1'%3e%3c/circle%3e%3ccircle cx='12' cy='19' r='1'%3e%3c/circle%3e%3c/svg%3e") 100% center/rem(16) no-repeat;
            opacity: 0.6;
          }
        }

        .c-sidebar__nav-link__logout {
          display: block;
          padding: $space-xxs $space-xs;
          border: rem(1) solid rgba($color-brand-primary-200, 0.6);
          border-radius: $global-radius;
          color: $color-brand-primary-200;
          font-size: $text-size-8;
          text-decoration: none;
          transition: $global-transition;

          @include mq(678px) {
            position: absolute;
            top: 0;
            right: 0;
            padding: $space-sm;
            border: 0;
            border-radius: $global-radius $global-radius 0 0;
            background-color: $color-brand-primary-200;
            color: $color-brand-primary-800;
            font-size: $text-size-7;
            opacity: 0;
          }

          @include acthocus() {
            background-color: lighten($color-brand-primary-100, 5%);
            color: $color-brand-primary-900;
          }
        }

        .c-sidebar__layout-btn {
          display: none;

          @include mq(678px) {
            display: block;
            width: 100%;
            margin: 0 $space-md $space-xs;
            padding-left: $space-md;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 32 32' stroke='%23f7f7f7' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M3 4v25h25V4H3zm24 24H4V11h23v17zm0-18H4V5h23v5z'/%3E%3C/svg%3E") left center/$space-unit no-repeat;
            color: $color-grey-100;
            font-size: $text-size-7;
            text-align: left;
            opacity: 0.5;

            @include acthocus() {
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 32 32' stroke='%23f7f7f7' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M3 4v25h25V4H3zm24 24H4V11h23v17zm0-18H4V5h23v5z'/%3E%3C/svg%3E") left center/$space-unit no-repeat;
              color: $color-grey-100;
              opacity: 0.8;
              text-decoration: none;
            }

            &::after {
              content: none;
            }
          }
        }


/* ==========================================================================
   #HORIZONTAL SIDEBAR STYLES
   ========================================================================== */

.c-sidebar--horizontal {

  @include mq(782px) {

    flex-basis: 100%;
    width: 100%;
    padding: 0 $space-xl;

    > .c-sidebar__wrapper {
      display: flex;
      position: static;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      min-height: auto;
    }

    .c-sidebar__header {
      margin: 0;
    }

    .c-logo {
      width: rem(50);
      margin-bottom: rem(-8);
    }

    .c-logo__text {
      display: none;
    }

    .c-sidebar__nav {
      position: static;
      width: auto;
      margin: 0;
      padding: 0;

      &::before {
        content: none;
      }
    }

    .c-sidebar__nav__item {
      display: inline-block;
    }

    .c-sidebar__nav__item--parent {

      > .c-sidebar__nav__link.is-active {
        border: 0;
        background: lighten($color-brand-secondary-900, 3%);
      }
    }

    .c-sidebar__nav__link {
      display: block;
      z-index: 5;
      position: relative;
      padding: $space-lg $space-unit;
      @include text-preset-8;

      @include hocus() {
        background-color: lighten($color-brand-secondary-1000, 2.5%);
        color: $color-brand-primary-100;
      }

      &::after {
        top: 47.5%;
        right: $space-xs;
      }

      &.is-active {

        &::after {
          top: 45%;
        }
      }

      &.is-active {
        border-right: 0;
        border-bottom: rem(4) solid $color-brand-tertiary-600;
        background: radial-gradient(circle at center 130%, lighten($color-brand-secondary-900, 10%) 20%, transparent 60%);
        font-size: $text-size-7;


        & + .c-sidebar__nav-child {
          display: flex;
          transform: translateY(0);
          padding: 0 $space-xl;
          background-color: darken($color-brand-secondary-1000, 4%);
          opacity: 1;
          pointer-events: auto;
          overflow: auto;

          .c-sidebar__nav-child__link {
            padding: $space-unit $space-md;
          }
        }
      }
    }

    .c-sidebar__nav-child {
      display: flex;
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;
      padding: 0;
      transform: translateY(rem(-60));
      background-color: transparent;
      opacity: 0;
      pointer-events: none;
      transition: $global-transition;
    }

    .c-sidebar__nav-child__link {
      border-bottom: rem(4) solid transparent;
      background-color: transparent;

      &.is-active {
        border-right: 0;
        border-color: $color-brand-tertiary-600;
        background: radial-gradient(circle at center 160%, lighten($color-brand-secondary-900, 10%) 30%, transparent 80%);
      }
    }

    .c-sidebar__footer {
      display: flex;
      flex-direction: column-reverse;
      width: auto;
      margin: 0;
      text-align: center;
    }

    .c-sidebar__layout-btn {
      min-height: auto;
      margin: 0 0 rem(-6);
      padding: $space-xs 0 $space-xs $space-md;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 32 32' stroke='%23f7f7f7' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M3 4v25h25V4H3zm8 24H4V5h7v23zm16 0H12V5h15v23z'/%3E%3C/svg%3E");
      font-size: $text-size-8;
      text-align: center;

      &:hover,
      &:active,
      &:focus {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 32 32' stroke='%23f7f7f7' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M3 4v25h25V4H3zm8 24H4V5h7v23zm16 0H12V5h15v23z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-decoration: none;
      }
    }

    .c-sidebar__username-dropdown {
      margin: 0 0 rem(2);
      padding: rem(6) $space-xs;
      min-width: rem(100);

      @include hocus() {

        .c-sidebar__username {
          opacity: 1;
        }

        .c-sidebar__nav-link__logout {
          z-index: 0;
          transform: translateY(rem(28));
        }
      }
    }

    .c-sidebar__username {
      background-size: rem(12);
      font-size: $text-size-8;
    }

    .c-sidebar__nav-link__logout {
      padding: rem(6);
      border: rem(.5) solid transparent;
      border-radius: 0 0 $global-radius $global-radius;
      font-size: $text-size-8;

      &:hover {
        border-color: currentColor;
      }
    }
  }
}


.c-sidebar__nav__trigger {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    margin-left: auto;
    padding: 0;
    color: $color-white;

    @include mq(678px) {
      display: none;
    }

    @include acthocus() {
      color: $color-white;
    }
  }
    .c-sidebar__nav__trigger::before {
    display: inline-block;
    width: rem(40);
    height: rem(40);
    font-size: 3em;
    font-weight: 200;
    line-height: 0.7;
    text-align: right;
    content: "\2630"; // Hamburger icon ☰
  }
  .c-sidebar__nav__trigger.is-open::before {
    width: rem(24);
    font-size: 2rem;
    line-height: 1.2;
    content: "×"; // Close icon X
  }
  .c-sidebar__nav__trigger.is-open + .c-sidebar__nav,
  .c-sidebar__nav__trigger.is-open + .c-sidebar__nav + .c-sidebar__footer {
    display: block;
  }
