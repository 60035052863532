.o-badge--qty {
  color: $color-grey-500;
  font-size: rem(14);
  font-weight: 400;

  &::before {
    content: '('
  }

  &::after {
    content: ')'
  }
}
