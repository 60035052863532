.c-progress-steps {
}

  .c-progress-steps__list {
    display: flex;
    margin: 0;
    padding: 0;
  }

    .c-progress-steps__item {
      flex: 1;
      list-style-type: none;
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }

      &.is-active {
        .c-progress-steps__link {
          color: $color-brand-secondary-600;
        }

        .c-progress-steps__title {
          @include font-weight(bold);
          color: currentColor;

          &::before {
            border: rem(3) solid;
            background-color: $color-white;
            box-shadow: 0 0 0 rem(4) $color-white;
          }
        }
      }

      &.is-active ~ * {

        .c-progress-steps__link {
          color: $color-grey-300;

          @include hocus() {
            color: darken($color-grey-300, 5%);

            .c-progress-steps__title {
              color: $color-grey-500;

              &::before {
                color: darken($color-grey-300, 5%);
              }
            }
          }
        }

        .c-progress-steps__title {
          color: $color-grey-400;

          &::before {
            border: 0;
            background-color: $color-grey-300;
          }
        }
      }
    }

      .c-progress-steps__link {
        display: block;
        position: relative;
        width: 100%;
        margin-top: $space-xxxs;
        padding-top: $space-unit;
        border-top: $space-xxxs solid;
        color: $color-brand-secondary-600;
      }

        .c-progress-steps__title {
          @include font-weight(normal);
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          color: $color-brand-secondary-600;

          &::before {
            display: block;
            position: absolute;
            top: -#{$space-xs};
            width: rem(14);
            height: rem(14);
            border: 0;
            border-radius: 50%;
            background-color: currentColor;
            content: '';
          }
        }
