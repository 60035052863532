.o-stack > * + * {
  margin-top: $space-lg;
}
  .o-stack--lg > * + * {
    margin-top: $space-xl;
  }

  .o-stack--sm > * + * {
    margin-top: $space-unit;
  }

  .o-stack--xs > * + * {
    margin-top: $space-xs;
  }

.o-stack__horizontal > * + * {
  margin-left: $space-lg;
}

  .o-stack__horizontal--sm > * + * {
    margin-left: $space-unit;
  }
