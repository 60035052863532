.c-profile-header__container {
  margin: $space-xxs 0 0;
  padding: $space-unit $space-unit $space-xxl;
  border: rem(1) solid $color-grey-200;
  border-radius: $global-radius;
  background-color: $color-grey-100;
}

  .c-profile-header__container--company {

    > .c-profile-header__header {
      grid-template-columns: 1fr 1fr 2fr;
    }
  }

  .c-profile-header__container--person  {

    > .c-profile-header__header {
      grid-template-columns: 1fr 3fr;

      .c-profile-header__header-info-columns {
        grid-template-columns: repeat(auto-fit, minmax(rem(250), 1fr));
      }
    }
  }

  .c-profile-header__container--well {

    > .c-profile-header__header {
      grid-template-columns: 1fr 2fr;
    }
  }

  .c-profile-header__type {
    display: inline-block;
    margin-left: -#{$space-unit};
    padding: $space-xs $space-unit $space-xs;
    background-color: $color-grey-600;
    color: $color-white;
    font-size: $text-size-7;
    font-weight: 400;
    text-transform: uppercase;

    .c-profile-header__container--company > & {
      background-color: $color-brand-primary-500;
    }

    .c-profile-header__container--person > & {
      background-color: $color-warning-500;
    }
  }

  .c-profile-header__logo {
    display: flex;
    align-items: center;
    padding: $space-unit;
    border: rem(1) solid $color-grey-200;
    background-color: $color-white;

    > * {
      object-fit: contain;
    }
  }

  .c-profile-header__header {
    grid-gap: $space-unit;
    grid-template-rows: auto 1fr;
    display: grid;
  }

  .c-profile-header__header-info {

    .o-icon {
      margin-right: $space-xs;
      transform: translateY($space-xxxxs);
      opacity: 0.7;
    }
  }

  .c-profile-header__header-info--long {
    display: flex;
    padding-left: $space-unit;
    overflow: auto;
  }

    .c-profile-header__header-info-columns {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(rem(120), 1fr));
      grid-template-rows: min-content;
      grid-auto-rows: min-content;
      grid-column-gap: $space-md;
      grid-row-gap: $space-xs;
      max-height: rem(250);
    }

    .c-profile-header__header-info__item {
      display: flex;

      & + & {
        margin-top: $space-sm;
      }
    }
