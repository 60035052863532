// Flex alignments
.u-justify-start {
  justify-content: flex-start;
}

.u-justify-end {
  justify-content: flex-end;
}

.u-justify-between {
  justify-content: space-between;
}

.u-align-items-start {
  align-items: flex-start;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-end {
  align-items: flex-end;
}

.u-align-self-center {
  align-self: center;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex-wrap {
  flex-wrap: wrap;
}
