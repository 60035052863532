.c-global-filter {
  position: relative;
  padding: $space-sm;
  border-radius: $global-radius;
  background-color: $color-brand-primary-0;
  transition: $global-transition;

  > * + * {
    margin-top: $space-xxs;
  }

  @include hocus() {
    box-shadow: $global-box-shadow;
  }
}

  .c-global-filter__label {
    @include text-preset-6;
    color: $color-brand-secondary-900;
  }

  .c-input.c-global-filter__input,
  .c-input--power-select.c-global-filter__input .ember-power-select-trigger {
    border: rem(1.5) solid $color-brand-primary-100;

    &:focus,
    &:focus-within {
      border-color: $color-brand-primary-200;
    }
  }
