.c-error__message {
  color: $color-danger-800;
  position: absolute;
  margin: $space-xxs;
  left: 0;
  bottom: 0;
  font-size: rem(12);
  white-space: nowrap;
  display: none;
}

.error .c-error__message {
  display: block;
}
